import React from 'react';
import '../styles/ProfileForm.css';
import {UserForm} from "./UserForm";
import {NotificationForm} from "./NotificationForm";
import FullButton from "./FullButton";

const ProfileForm = ({
  editProfileData,
  notificationData,
  onChangeEditProfile,
  onSaveNotification,
  showEditProfileForm,
  showNotificationForm,
  onSaveButtonClick,
}) => {
  return (
      <div>
        <div className="edit-profile-form">
          {showEditProfileForm ? (
              <UserForm userData={editProfileData}
                        onChangeUser={onChangeEditProfile}
                        isLawyer={true}/>
          ) : null}
          {showNotificationForm ? (
              <NotificationForm notificationData={notificationData}
                                onSaveNotification={onSaveNotification}/>
          ) : null}
        </div>
        <div id="profile_form_save_btn" className="button-container">
          <FullButton
              label="Save"
              primary
              onClick={() => onSaveButtonClick(notificationData)}
              className="full-button"
          />
        </div>
      </div>
  );
};

export default ProfileForm;
