import React from 'react';
import FullButton from "./FullButton";
import '../styles/NavButtons.css';

const NavButtons = ({ labels, activeForm, onFormToggle }) => {
  return (
      <div className="nav-buttons">
        {labels.map((label, index) => (
            <div className="nav-button" key={index}>
              <FullButton
                  label={label}
                  navPrimary={activeForm === label}
                  onClick={() => onFormToggle(label)}
              />
            </div>
        ))}
      </div>
  );
};

export default NavButtons;
