import React from 'react';
import {LoginForm} from '../components/LoginForm';
import {AuthService} from '../services/AuthService';
import {TokenService} from '../services/TokenService';
import {useNavigate} from "react-router-dom";
import {clearStorageValues} from "../helpers/StorageHelper";

export const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState('');

  const handleLogin = (email, password) => {
    const modifiedPassword = password.split('').reverse().join('');
    AuthService.login(email, modifiedPassword)
    .then((response) => {
      const {token} = response.data;
      if (token) {
        handleLoginSuccess(token);
      }
    })
    .catch((currentError) => {
      const errors =
          currentError.response?.data?.errors
          || 'An error occurred during login.';
      handleLoginError(errors);
    });
  };

  const handleLoginSuccess = (token) => {
    clearStorageValues();
    TokenService.setToken(token);
    navigate('/dashboard')
  };

  const handleLoginError = (errors) => {
    setError(errors);
  };

  const handleResetPassword = () => {
    navigate('/forget');
  }

  const handleRedirectToSignUp = () => {
    navigate('/pricing');
  }

  return (
      <div>
        <LoginForm onLogin={handleLogin} onResetPassword={handleResetPassword} onRedirectToSignUp={handleRedirectToSignUp} error={error} setError={setError}/>
      </div>
  );
};