import React, { useEffect, useState } from 'react';
import { InputField } from './InputField';
import '../styles/GenericForm.css';
import { CustomToggleSlider } from './CustomToggleSlider';

export const NotificationForm = ({ notificationData, onSaveNotification }) => {
  const [email, setEmail] = useState(notificationData?.email);
  const [isEmailEnabled, setIsEmailEnabled] = useState(notificationData?.emailEnabled);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (notificationData) {
      setEmail(notificationData?.email);
      setIsEmailEnabled(notificationData?.emailEnabled);
    }
  }, [notificationData]);

  // useEffect can be used for handling updates to the parent component
  useEffect(() => {
    onSaveNotification({ email, isEmailEnabled });
  }, [email, isEmailEnabled]);

  const handleChange = (fieldName, value) => {
    switch (fieldName) {
      case 'email':
        setEmail(value);
        break;
      case 'isEmailEnabled':
        setIsEmailEnabled(value);
        break;
      default:
        break;
    }
  };

  return (
      <div className="form-container">
        <div className="section-title">
          <h2>Notifications</h2>
          <h4>How would you like us to notify you</h4>
        </div>
        <div className="form-row">
          <div className="form-column">
            <label className="form-label">Notification email:</label>
            <InputField
                type="email"
                value={email || ''}
                onChange={(e) => handleChange('email', e.target.value)}
                fieldLabel="Enter your email"
            />
          </div>
          <div className="centered-container">
            <CustomToggleSlider
                checked={isEmailEnabled || false}
                onChange={(e) => handleChange('isEmailEnabled', e)}
            />
          </div>
        </div>
      </div>
  );
};