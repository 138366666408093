import { axiosInstance, setAuthToken } from "../api/axios";

export const UserService = {
  getUsers() {
    return axiosInstance.get('/api/user/all');
  },

  addUser(email, password) {
    setAuthToken();
    return axiosInstance.post('/api/user/add', {email, password});
  },

  removeUser(userId) {
    return axiosInstance.delete(`/api/user/remove/${userId}`);
  },

  deleteUser() {
    return axiosInstance.delete(`/api/user/delete`);
  },
};
