import {axiosInstance, setAuthToken} from "../api/axios";

export const SubscriptionService = {
  getSubscription() {
    return axiosInstance.get('/api/subscription/info');
  },

  getSubscriptionByUserId(userId) {
    setAuthToken();
    return axiosInstance.get(`/api/subscription/admin/info/${userId}`);
  },

  getAllSubscriptions() {
    setAuthToken();
    return axiosInstance.get('/api/subscription/all');
  },

  getAllSubscriptionsByPeriod(period) {
    setAuthToken();
    return axiosInstance.get(`/api/subscription/all/${period}`);
  },

  cancelSubscription(subscriptionRef) {
    setAuthToken();
    return axiosInstance.get(`/api/subscription/cancel/${subscriptionRef}`);
  },
};