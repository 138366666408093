import React from 'react';
import '../styles/Box.css';
import {IoStatsChart} from "react-icons/io5";

const Box = ({ title, children }) => {
  return (
      <div className="box">
        <h2>{title}</h2>
        <div className="box-content">
          <p>{children}</p>
          <IoStatsChart size={23} />
        </div>
      </div>
  );
};

export default Box;
