import React, {useEffect, useState} from 'react';
import {InputField} from './InputField';
import '../styles/GenericForm.css';
import {CustomToggleSlider} from "./CustomToggleSlider";
import FullButton from "./FullButton";

export const UserForm = ({
      userData,
      subscriptions,
      onChangeUser,
      onSaveUser,
      onDeleteUser,
      isNew,
      isAdmin
    }) => {
      const [firstName, setFirstName] = useState(userData?.firstName);
      const [lastName, setLastName] = useState(userData?.lastName);
      const [user, setUser] = useState(userData?.user || {});
      const disabled = isNew || isAdmin ? '' : 'form-column-disable';
      const title = isNew ? 'New User' : isAdmin ? 'User #' + userData?.user?.userId
          : 'Edit Profile';
      const roles = [{roleId: 1, type: "LAWYER"}, {roleId: 2, type: "ADMIN"}];

      /* eslint-disable react-hooks/exhaustive-deps */
      useEffect(() => {
        if (userData) {
          setFirstName(userData?.firstName)
          setLastName(userData?.lastName)
          setUser(userData?.user)
        }
      }, [userData]);

      /* eslint-disable react-hooks/exhaustive-deps */
      useEffect(() => {
        onChangeUser({firstName, lastName, user});
      }, [firstName, lastName, user]);

      const handleChange = (fieldName, value) => {
        switch (fieldName) {
          case 'firstName':
            setFirstName(value);
            break;
          case 'lastName':
            setLastName(value);
            break;
          case 'email':
            setUser({...user, email: value});
            break;
          case 'password':
            setUser({...user, password: value});
            break;
          case 'role':
            const selectedRole = value === '2' ? roles[1] : roles[0];
            setUser({...user, role: selectedRole});
            break;
          case 'subscription':
            setUser({
              ...user,
              subscription: subscriptions.find(
                  sub => sub.productId === value)
            });
            break;
          case 'locked':
            setUser({...user, locked: value});
            break;
          case 'expired':
            setUser({...user, expired: value});
            break;
          default:
            break;
        }
      };

      return (
          <div className="form-container">
            <div className="section-title">
              <div className="form-row">
                <div className="form-column">
                  <h2>{title}</h2>
                  <h4>How would you like us to call you</h4>
                </div>
                {isAdmin && (
                    <div className="form-column-button">
                      <FullButton label="Remove" primary onClick={onDeleteUser}/>
                    </div>
                )}
              </div>
            </div>
            <div id="user_form_form_row_1" className="form-row">
              <div className="form-column">
                <InputField
                    label="First Name:"
                    type="text"
                    value={firstName || ''}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    fieldLabel="Enter your first name"
                />
              </div>
              <div className="form-column">
                <InputField
                    label="Last Name:"
                    type="text"
                    value={lastName || ''}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                    fieldLabel="Enter your last name"
                />
              </div>
            </div>
            <div className="form-row">
              <div className={`form-column ${disabled}`}>
                <InputField
                    label="Email:"
                    type="text"
                    value={user?.email || ''}
                    onChange={(e) => handleChange('email', e.target.value)}
                    fieldLabel="Enter your email"
                />
              </div>
            </div>

            {isNew && (
                <div className="form-row">
                  <div className={`form-column ${disabled}`}>
                    <InputField
                        label="Password:"
                        type="password"
                        value={user?.password || ''}
                        onChange={(e) => handleChange('password', e.target.value)}
                        fieldLabel="Enter your password"
                        showPasswordToggle={true}
                    />
                  </div>
                  <div className={`form-column ${disabled}`}>
                    <InputField
                        label="Confirm Password:"
                        type="password"
                        value={user?.password || ''}
                        onChange={(e) => handleChange('confirmPassword',
                            e.target.value)}
                        fieldLabel="Enter your password"
                        showPasswordToggle={true}
                    />
                  </div>
                </div>
            )}

            {isAdmin || isNew ? (
                <div className="form-row">
                  <div className={`form-column`}>
                    <InputField
                        label="Role:"
                        type="dropdown"
                        options={roles}
                        optionId="roleId"
                        value={user?.role?.roleId || roles[0].roleId}
                        onChange={(e) => handleChange('role', e.target.value)}
                        fieldLabel="Select your role"
                    />
                  </div>
                  <div className={`form-column`}>
                    {subscriptions && (
                        <InputField
                            label="Subscription:"
                            type="dropdown"
                            options={subscriptions}
                            optionId="productId"
                            value={user?.subscription?.productId
                            || subscriptions[0]?.productId}
                            onChange={(e) => handleChange('subscription',
                                e.target.value)}
                            fieldLabel="Select your subscription"
                        />
                    )}
                  </div>
                </div>
            ) : null}

            {isAdmin && (
                <div className="form-row">
                  <div className="form-column">
                    <label className="form-label">Is Locked:</label>
                  </div>
                  <div className="form-column">
                    <div className="centered-container">
                      <CustomToggleSlider
                          checked={user?.locked || false}
                          onChange={(e) => handleChange('locked', e)}
                      />
                    </div>
                  </div>
                  <div className="form-column">
                    <label className="form-label">Is Expired:</label>
                  </div>
                  <div className="form-column">
                    <div className="centered-container">
                      <CustomToggleSlider
                          checked={user?.expired || false}
                          onChange={(e) => handleChange('expired', e)}
                      />
                    </div>
                  </div>
                </div>
            )}

            {isNew || isAdmin ? (
                <div className="form-row">
                  <div className={`form-column`}>
                    <FullButton
                        label="Save"
                        primary
                        className="full-button"
                        onClick={onSaveUser}
                    />
                  </div>
                </div>
            ) : null}
          </div>
      );
    }
;
