import React, {useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Admin.css';
import NavButtons from "../components/NavButtons";
import {useAdmin} from "../api/useAdmin";
import AdminForm from "../components/AdminForm";
import {LawyerService} from "../services/LawyerService";
import {UserService} from "../services/UserService";
import {SubscriptionService} from "../services/SubscriptionService";

export const Admin = () => {
  const {lawyers} = useAdmin();
  const [activeForm, setActiveForm] = useState("All Users");
  const [progressBarRendered, setProgressBarRendered] = useState(false);
  const [lawyerData, setLawyerData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [selectedLawyerData, setSelectedLawyerData] = useState();

  useEffect(() => {
    if (lawyers) {
      setLawyerData(lawyers);
    }

    SubscriptionService.getAllSubscriptions()
    .then((res) => {
      setSubscriptionData(res.data)
    })
    .catch(error => {
      console.error('Error fetching subscriptions:', error);
    })

  }, [lawyers]);

  const handleButtonNewUser = () => {
    setActiveForm('New User');
  }

  const handleSelectUser = (params) => {
    const userId = params.row.user_id;
    if (userId) {
      LawyerService.getLawyerByUserId(userId)
      .then(response => {
        setSelectedLawyerData(response.data)
        setActiveForm('Selected User')
      })
      .catch(error => {
        console.error('Error fetching lawyer:', error);
      })
    }
  }

  const handleOnChangeUser = (data) => {
    setSelectedLawyerData((prevData) => ({
      ...prevData,
      ...data,
    }));
  }

  const handleOnSaveUser = () => {
    const user = selectedLawyerData.user;

    if (activeForm === "New User") {
      UserService.addUser(user.email, user.password)
      .then((res) => {
        const userId = res.data.user.userId;

        return LawyerService.updateLawyer(userId, selectedLawyerData);
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error saving user or lawyer:', error);
      });
    } else {
      LawyerService.updateLawyer(user.userId, selectedLawyerData)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error updating lawyer:', error);
      });
    }
  };

  const handleOnDeleteUser = () => {
    const user = selectedLawyerData.user;

    UserService.removeUser(user.userId)
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error('Error delete user:', error);
    });
  }

  const navButtonLabels = ["All Users", "New User"];

  // Use useEffect to set the progressBarRendered flag
  useEffect(() => {
    if (!progressBarRendered) {
      setProgressBarRendered(true);
    }
  }, [activeForm, progressBarRendered]);

  return (
      <div className="admin">
        <h1>Admin</h1>
        <div className="nav-buttons-container-mobile">
          <NavButtons
              labels={navButtonLabels}
              activeForm={activeForm}
              onFormToggle={setActiveForm}
          />
        </div>
        <div className="admin-container">
          <div className="nav-buttons-container">
            <NavButtons
                labels={navButtonLabels}
                activeForm={activeForm}
                onFormToggle={setActiveForm}
            />
          </div>
          <div className="edit-profile-content">
            <div className="forms-container">
              <div className="admin-form-container">
                <AdminForm
                    lawyerData={lawyerData}
                    selectedLawyerData={selectedLawyerData}
                    subscriptionData={subscriptionData}
                    showLawyerList={activeForm === 'All Users'}
                    showNewUser={activeForm === 'New User'}
                    showSelectedUser={activeForm === 'Selected User'}
                    onClickNewUser={handleButtonNewUser}
                    onSelectUser={handleSelectUser}
                    onChangeUser={handleOnChangeUser}
                    onSaveUser={handleOnSaveUser}
                    onDeleteUser={handleOnDeleteUser}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={2000}/>
      </div>
  );
};
