import React, { useEffect, useState } from 'react';
import '../styles/GenericForm.css';
import SelectedOption from './SelectedOption';
import { InputField } from "./InputField";
import { getSubscriptionIcon } from "../helpers/SubscriptionHelper";
import {FaLock} from "react-icons/fa";
import {getLawyer} from "../helpers/StorageHelper";

export const KeywordForm = ({
  selectedKeywords,
  onSaveKeyword,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [keyword, setKeyword] = useState('');
  const { icon, width, height } = getSubscriptionIcon('PRO');
  const lawyerData = getLawyer;
  const subscriptionType = lawyerData?.subscriptionType;
  const limit = lawyerData?.subscriptionLimit;

  useEffect(() => {
    if (selectedOptions.length === 0 && selectedKeywords) {
      const newOptions = selectedKeywords.map(keyword => ({ name: keyword }));
      setSelectedOptions(newOptions);
    }
  }, [selectedKeywords]);

  useEffect(() => {
    onSaveKeyword(selectedOptions.map((option) => option.name));
  }, [selectedOptions, onSaveKeyword]);

  const handleOptionSelect = (e) => {
    if (e.key === 'Enter') {
      const newOption = { name: keyword };
      setSelectedOptions([...selectedOptions, newOption]);
      setKeyword('');
    }
  };

  const handleOptionRemove = (option) => {
    const updatedOptions = selectedOptions.filter(
        (selected) => selected !== option
    );
    setSelectedOptions(updatedOptions);
  };

  return (
      <div className={`form-container-categories ${subscriptionType !== 'PRO' ? 'disabled' : ''}`}>
        <div className="section-title">
          <div className="form-row">
            <div className="form-column">
              <h2>By Keyword {subscriptionType !== 'PRO' && <FaLock style={{fontSize:15, marginLeft: "5px", marginBottom: "5px"}}/>}</h2>
            </div>
            {subscriptionType !== 'PRO' ? (
                <img
                    src={icon}
                    alt={`pro subscription`}
                    height={height}
                    width={width}
                />
            ) : (
                <h2>{selectedOptions.length}/{limit}</h2>
            )}
          </div>
          <div className="form-row">
            <div className="form-column">
              <h4>A keyword can be anything</h4>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <label className="form-label">Add keywords:</label>
            <InputField
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={handleOptionSelect}
                fieldLabel="Please type a keyword"
                disabled={subscriptionType !== 'PRO'}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <div className="selected-options">
              {selectedOptions &&
              selectedOptions.map((option, index) => (
                  <SelectedOption
                      key={index}
                      option={option}
                      onRemove={handleOptionRemove}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};