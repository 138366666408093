import React, {useState} from 'react';
import {InputField} from './InputField';
import '../styles/Login.css';
import FullButton from './FullButton';

export const ForgetPasswordForm = ({onForgetPassword, message, setMessage}) => {
  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email before submitting
    if (!validateEmail(email)) {
      setMessage('Please enter a valid email address');
      return;
    }

    setMessage('');
    setIsDisabled(true)
    onForgetPassword(email);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  // Email validation function (Same as used in the LoginForm)
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="form-title">Reset Password</h2>
          <InputField
              label="Email:"
              type="text"
              value={email}
              onChange={handleChangeEmail}
              fieldLabel="Enter email"
          />
          <div className="form-group">
            <FullButton label="RESET PASSWORD" primary onClick={handleSubmit}
                        disabled={isDisabled}/>
          </div>
          <div className="message-group">
            {message && <p>{message}</p>}
          </div>
        </form>
      </div>
  );
};
