import React from 'react';
import PreferencesCategoryForm from './PreferencesCategoryForm';

export const AuthorForm = ({ authorData, selectedAuthors, onSaveAuthor }) => {

  return (
      <PreferencesCategoryForm
          categoryData={authorData.authors}
          selectedCategories={selectedAuthors}
          onSaveCategory={onSaveAuthor}
          title="By Author"
          label="author"
          description="These are types of authors"
          idField="authorId"
          nameField="name"
          placeholderText="Please search and select"
      />
  );
};
