import React, { useState } from 'react';
import '../styles/CustomToggleSlider.css';

export const CustomToggleSlider = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
      <label className="toggle-slider-container">
        <input
            type="checkbox"
            checked={isChecked}
            onChange={handleToggle}
            className="toggle-checkbox"
        />
        <div className={`toggle-slider-bar ${isChecked ? 'on' : 'off'}`}>
          <div className="toggle-slider-thumb" />
        </div>
      </label>
  );
};
