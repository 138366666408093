import React from 'react';
import PreferencesCategoryForm from './PreferencesCategoryForm';

export const OccupationForm = ({ occupationData, selectedOccupations, onSaveOccupation }) => {

  return (
      <PreferencesCategoryForm
          categoryData={occupationData.occupations}
          selectedCategories={selectedOccupations}
          onSaveCategory={onSaveOccupation}
          category="occupations"
          title="By Occupation"
          label="occupation"
          description="These are types of occupations"
          idField="occupationId"
          nameField="name"
          placeholderText="Please search and select"
      />
  );
};
