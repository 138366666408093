import React, {useEffect, useState} from 'react';
import {TokenService} from "../services/TokenService";
import {Outlet, useNavigate} from 'react-router-dom';
import {useLawyer} from "../api/useLawyer";
import {Sidebar} from "../components/Sidebar";
import {TopBar} from "../components/TopBar";
import '../styles/Sidebar.css';
import {useSubscription} from "../api/useSubscription";
import {getLawyer} from "../helpers/StorageHelper";
import {useMediaQuery} from 'react-responsive';

export const DashboardLayout = () => {
  const navigate = useNavigate();
  const {lawyer} = useLawyer();
  const {subscription} = useSubscription();
  const [sidebarOpen, setSidebarOpen] = useState(sessionStorage.getItem('sidebarOpen') !== 'false');
  const isResponsive = useMediaQuery({query: '(max-width: 768px)'});

  const toggleSidebar = () => {
    const newState = !sidebarOpen;
    setSidebarOpen(newState);
    sessionStorage.setItem('sidebarOpen', newState.toString());
  };

  const selectOption = (option) => {
    sessionStorage.setItem('sidebarSelectedOption', option);
  };

  const handleSignOut = () => {
    TokenService.removeToken();
    sessionStorage.removeItem('sidebarSelectedOption');
    handleLogout();
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const handleOverlayClick = () => {
    if (isResponsive) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    const lawyerData = getLawyer;

    const firstName = lawyer?.firstName || lawyerData?.firstName;
    const lastName = lawyer?.lastName || lawyerData?.lastName;
    const role = lawyer.user?.role?.type || lawyerData?.role;
    const subscriptionType = lawyer.user?.subscription?.type || lawyerData?.subscriptionType;
    const subscriptionLimit = lawyer.user?.subscription?.preferencesLimit || lawyerData?.subscriptionLimit;

    const updatedLawyerData = {
      firstName,
      lastName,
      role,
      subscriptionType,
      subscriptionLimit,
      subscription,
    };

    localStorage.setItem('lawyer', JSON.stringify(updatedLawyerData));
  }, [lawyer]);

  return (
      <div className="App">
        <TopBar lawyer={lawyer} sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar} handleSignOut={handleSignOut}/>
        <Sidebar lawyer={lawyer} subscription={subscription}
                 isOpen={sidebarOpen} toggleSidebar={toggleSidebar}
                 selectOption={selectOption}/>
        {sidebarOpen && isResponsive && <div className="overlay"
                                             onClick={handleOverlayClick}/>}
        <div className={`content ${!sidebarOpen ? 'content-full-page' : ''}`}>
          <Outlet/>
        </div>
      </div>
  );
};