import { toast } from 'react-toastify';
import '../styles/Toast.css';

export default class ToastHelper {
  static success(message) {
    toast.success(message);
  }

  static error(message) {
    toast.error(message);
  }

  static warning(message) {
    toast.warning(message);
  }

  static info(message) {
    toast.info(message);
  }

  static custom(message, options) {
    toast(message, options);
  }
}
