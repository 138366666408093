import {useEffect, useState} from "react";
import {setAuthToken} from "./axios";
import {SubscriptionService} from "../services/SubscriptionService";

export function useSubscription() {
  const [subscription, setSubscription] = useState({});

  useEffect(() => {
    // Fetch the data from the API when the component mounts
    setAuthToken();

    SubscriptionService.getSubscription()
    .then(response => {
      setSubscription(response.data)
    })
    .catch(() => {

    })
  }, []);

  return {subscription};
}