import React, {useState} from 'react';
import '../styles/InputField.css';
import {FaEye, FaEyeSlash} from 'react-icons/fa';

export const InputField = ({
  label,
  type,
  value,
  onChange,
  onKeyPress,
  fieldLabel,
  disable,
  showPasswordToggle,
  options,
  optionId,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
      <div className="custom-form-group">
        {label && (
            <label htmlFor={label} className="input-label">
              {label}
            </label>
        )}
        <div className="input-container">
          {type === 'dropdown' ? (
              <select
                  disabled={disable}
                  id={label}
                  className="custom-form-control custom-form-input"
                  value={value}
                  onChange={onChange}
              >
                {options.map((option) => (
                    <option key={option[optionId]} value={option[optionId]}>
                      {option.type + (option.duration ? ' - ' + option.duration : '') + (option.recurring ? ' - R' : '')}
                    </option>
                ))}
              </select>
          ) : (
              <input
                  type={showPassword ? 'text' : type}
                  disabled={disable}
                  id={label}
                  className="custom-form-control custom-form-input"
                  value={value}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  placeholder={fieldLabel}
              />
          )}
          {showPasswordToggle && type === 'password' && (
              <span className="toggle-password"
                    onClick={handleTogglePasswordVisibility}>
            {showPassword ? (
                <FaEyeSlash/> // Eye slash icon when password is shown
            ) : (
                <FaEye/> // Eye icon when password is hidden
            )}
          </span>
          )}
        </div>
      </div>
  );
};