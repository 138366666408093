export const TokenService = {

  token: 'token',

  getToken() {
    return localStorage.getItem(this.token);
  },

  setToken(token) {
    localStorage.setItem(this.token, token);
  },

  removeToken() {
    localStorage.removeItem(this.token);
  },

  isAuthenticated() {
    const token = this.getToken();
    return !!token;
  },

  getTokenInfo() {
    const token = this.getToken();
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(atob(base64));
    }
    return null;
  },

  getEmail() {
    return this.getTokenInfo()?.sub
  }
};
