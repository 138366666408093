import React, {useMemo} from 'react';
import Table from "./Table";
import {FaFilePdf} from 'react-icons/fa6';
import {PiFileHtml} from "react-icons/pi";
import '../styles/DocumentTable.css';

const DocumentTable = ({documentData}) => {
  const flattenedData = documentData.map(document => ({
    reference: document?.reference,
    id: document?.celex,
    type: document?.type,
    title: document?.title,
    created: document?.created,
    htmlLink: document?.documentLinks.find(link => link.type === 'html')?.value,
    pdfLink: document?.documentLinks.find(link => link.type === 'pdf')?.value,
  }));

  const columns = useMemo(() => [
    {
      field: 'id',
      headerName: 'Celex',
      width: 120,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 110,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 350,
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 130,
    },
    {
      field: 'htmlLink',
      headerName: '',
      width: 50,
      isLink: true,
      icon: <PiFileHtml style={{fontSize: '20px'}}/>,
    },
    {
      field: 'pdfLink',
      headerName: '',
      flex: 50,
      isLink: true,
      icon: <FaFilePdf style={{color: 'red', fontSize: '18px'}}/>,
    },
  ], []);

  return (
      <div id="document_table_container" className="document-table-container">
        <h2 className="heading-title">Legislation published today</h2>
        <Table columns={columns} data={flattenedData}/>
      </div>
  );
};

export default DocumentTable;
