import React, { useState, useEffect } from 'react';
import '../styles/GenericForm.css';
import { Checkbox } from "@mui/material";

export const TypeForm = ({ selectedTypes, onSavesTypes }) => {
  const [hasRegulations, setHasRegulations] = useState(selectedTypes?.hasRegulations || false);
  const [hasDecisions, setHasDecisions] = useState(selectedTypes?.hasDecisions || false);
  const [hasDirectives, setHasDirectives] = useState(selectedTypes?.hasDirectives || false);

  const handleChecked = (type, checked) => {
    const newState = {
      hasRegulations: type === 'Regulations' ? checked : hasRegulations,
      hasDecisions: type === 'Decisions' ? checked : hasDecisions,
      hasDirectives: type === 'Directives' ? checked : hasDirectives,
    };

    setHasRegulations(newState.hasRegulations);
    setHasDecisions(newState.hasDecisions);
    setHasDirectives(newState.hasDirectives);

    if (JSON.stringify(newState) !== JSON.stringify(selectedTypes)) {
      onSavesTypes(newState);
    }
  };

  useEffect(() => {
    setHasRegulations(selectedTypes?.hasRegulations || false);
    setHasDecisions(selectedTypes?.hasDecisions || false);
    setHasDirectives(selectedTypes?.hasDirectives || false);
  }, [selectedTypes]);

  return (
      <div className={`form-container-categories`}>
        <div className="section-title">
          <div className="form-row">
            <div className="form-column">
              <h2>By Type</h2>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <h4>A type of legislation</h4>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <label className="form-label">Select types:</label>
            <div className='form-row'>
              <div className='form-column'>
                <span>Regulations:</span>
                <Checkbox
                    name='Regulations'
                    checked={hasRegulations}
                    onChange={(e) => handleChecked('Regulations', e.target.checked)}
                />
              </div>
              <div className='form-column'>
                <span>Decisions:</span>
                <Checkbox
                    name='Decisions'
                    checked={hasDecisions}
                    onChange={(e) => handleChecked('Decisions', e.target.checked)}
                />
              </div>
              <div className='form-column'>
                <span>Directives:</span>
                <Checkbox
                    name='Directives'
                    checked={hasDirectives}
                    onChange={(e) => handleChecked('Directives', e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
