import React from 'react';

const FullButton = ({
  label,
  primary,
  secondary,
  navPrimary,
  cancel,
  onClick,
  disabled
}) => {
  let buttonClassName = 'full-button nav-secondary';

  if (primary) {
    buttonClassName = 'full-button primary';
  } else if (secondary) {
    buttonClassName = 'full-button secondary';
  } else if (navPrimary) {
    buttonClassName = 'full-button nav-primary';
  } else if (cancel) {
    buttonClassName = 'full-button nav-cancel';
  }

  return (
      <button className={buttonClassName} onClick={onClick} disabled={disabled}>
        {label}
      </button>
  );
};

export default FullButton;
