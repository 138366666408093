import React, {useEffect, useState} from 'react';
import {InputField} from './InputField';
import '../styles/RegisterForm.css';
import FullButton from './FullButton';
import {BsFillPatchCheckFill, BsFillPatchExclamationFill} from 'react-icons/bs';

export const RegisterForm = ({
  onRegister,
  onRedirectToSignIn,
  errors,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResponsiveLayout, setIsResponsiveLayout] = useState(false);

  const checkResponsiveLayout = () => {
    const windowWidth = window.innerWidth;
    setIsResponsiveLayout(windowWidth <= 768);
  };

  useEffect(() => {
    checkResponsiveLayout();
    window.addEventListener('resize', checkResponsiveLayout);
    return () => {
      window.removeEventListener('resize', checkResponsiveLayout);
    };
  }, []);

  const ValidationPoint = ({isValid, text}) => (
      <div className={`point ${isValid ? 'green' : 'red'}`}>
        {isValid ? <BsFillPatchCheckFill/> :
            <BsFillPatchExclamationFill/>} {text}
      </div>
  );

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePasswordLength = (password) => {
    return password.length >= 8;
  };

  const validatePasswordLowercaseAndUppercase = (password) => {
    return password.match(/[a-z]/) && password.match(/[A-Z]/);
  };

  const validatePasswordDigit = (password) => {
    return password.match(/\d/);
  };

  const validatePasswordSpecialCharacter = (password) => {
    return password.match(/[@$!%*?&]/);
  };

  const validateConfirmPassword = (confirmPassword) => {
    return password === confirmPassword && confirmPassword;
  };

  const isEmailValid = validateEmail(email);
  const isPasswordLengthValid = validatePasswordLength(password);
  const isPasswordLowercaseValid = validatePasswordLowercaseAndUppercase(
      password);
  const isPasswordDigitValid = validatePasswordDigit(password);
  const isPasswordSpecialCharacterValid = validatePasswordSpecialCharacter(
      password);
  const isConfirmPasswordValid = validateConfirmPassword(confirmPassword);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handlePrivacyChange = (e) => {
    setPrivacyChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
        !isEmailValid ||
        !isPasswordLengthValid ||
        !isPasswordLowercaseValid ||
        !isPasswordDigitValid ||
        !isPasswordSpecialCharacterValid ||
        !isConfirmPasswordValid ||
        !termsChecked ||
        !privacyChecked
    ) {
      return;
    }

    setIsSubmitting(true);
    onRegister(email, password);
  };

  const handleOnSignIn = () => {
    onRedirectToSignIn();
  };

  const isButtonDisabled =
      !isEmailValid ||
      !isPasswordLengthValid ||
      !isPasswordLowercaseValid ||
      !isPasswordDigitValid ||
      !isPasswordSpecialCharacterValid ||
      !isConfirmPasswordValid ||
      !termsChecked ||
      !privacyChecked ||
      isSubmitting;

  return (
      <div className={`login-form-container ${isResponsiveLayout
          ? 'responsive-layout' : ''}`}>
        <div className="register-form-column"
             style={{height: "0px", margin: "0px"}}/>
        <div className="register-form-column">
          <form className="register-form" onSubmit={handleSubmit}>
            <h2 className="form-title">Sign Up</h2>
            <InputField
                label="Email:"
                type="text"
                value={email}
                onChange={handleEmailChange}
                fieldLabel="Enter email"
            />
            <InputField
                label="Password:"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                fieldLabel="Enter password"
                showPasswordToggle={true}
            />
            <InputField
                label="Confirm Password:"
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                fieldLabel="Confirm password"
                showPasswordToggle={true}
            />
            <div className="form-group">
              <input type="checkbox" id="terms" checked={termsChecked}
                     style={{marginRight: "5px"}} onChange={handleTermsChange}/>
              <label htmlFor="terms"> I agree to the&nbsp;
                <a href="/docs/terms-and-conditions" target='_blank'
                   rel='noopener noreferrer'
                   style={{
                     textDecoration: "none",
                     fontWeight: "bold",
                     fontSize: "15px"
                   }}>Terms and Conditions
                </a>
              </label>
            </div>
            <div className="form-group">
              <input type="checkbox" id="privacy" checked={privacyChecked}
                     style={{marginRight: "5px"}}
                     onChange={handlePrivacyChange}/>
              <label htmlFor="privacy">I agree to the&nbsp;
                <a href="/docs/privacy-notice" target='_blank'
                   rel='noopener noreferrer'
                   style={{
                     textDecoration: "none",
                     fontWeight: "bold",
                     fontSize: "15px"
                   }}>Privacy Notice
                </a>
              </label>
            </div>
            <div className="form-group">
              <FullButton label="SIGN UP" primary onClick={handleSubmit}
                          disabled={isButtonDisabled}/>
            </div>
            <div className="message-group">
              {errors.length > 0 && (
                  <div className="error-list">
                    {errors.map((error, i) => (
                        <div key={i} className="error-message">
                          {error}
                        </div>
                    ))}
                  </div>
              )}
              <div className="form-group"
                   style={{marginBottom: '5px', marginTop: '30px'}}>
                <span>Already have an account? </span>
                <button type="button" className="forgot-password"
                        onClick={handleOnSignIn}>
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="register-form-column" style={{justifyContent: "start"}}>
          <div className="validation-container">
            <div className="validation-points">
              {email && <ValidationPoint isValid={isEmailValid}
                                         text="Email is valid"/>}
              {password && <ValidationPoint isValid={isPasswordLengthValid}
                                            text="Password is at least 8 characters long"/>}
              {password && <ValidationPoint isValid={isPasswordLowercaseValid}
                                            text="Password contains at least one lowercase and one uppercase letter"/>}
              {password && <ValidationPoint isValid={isPasswordDigitValid}
                                            text="Password contains at least one digit"/>}
              {password && <ValidationPoint
                  isValid={isPasswordSpecialCharacterValid}
                  text="Password contains at least one special character from @$!%*?&"/>}
              {password && <ValidationPoint isValid={isConfirmPasswordValid}
                                            text="Passwords match"/>}
              {password && <ValidationPoint isValid={termsChecked}
                                            text="Terms and Conditions are accepted"/>}
              {password && <ValidationPoint isValid={privacyChecked}
                                            text="Privacy Notice is accepted"/>}
            </div>
          </div>
        </div>
      </div>
  );
};
