import { axiosInstance } from "../api/axios";

export const DashboardService = {
  getCountAllToday() {
    return axiosInstance.get('/api/dashboard/legislation/today/count');
  },
  getLegalDocuments(page, pageSize, language, startDate, endDate) {
    const params = {
      page,
      pageSize,
      language,
      startDate,
      endDate,
    };

    return axiosInstance.get('/api/dashboard/legislation', { params });
  },
};
