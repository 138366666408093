import React, { useState } from 'react';
import { InputField } from './InputField';
import '../styles/Login.css';
import FullButton from './FullButton';

export const LoginForm = ({ onLogin, onResetPassword, onRedirectToSignUp, error, setError }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError('Please provide an email and a password');
      return;
    }

    setError('');
    onLogin(email, password);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleResetPassword = () => {
    onResetPassword();
  };

  const handleRedirectToSignUp = () => {
    onRedirectToSignUp();
  }

  return (
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="form-title">Sign In</h2>
          <InputField
              label="Email:"
              type="text"
              value={email}
              onChange={handleChangeEmail}
              fieldLabel="Enter email"
          />
          <InputField
              label="Password:"
              type="password"
              value={password}
              onChange={handleChangePassword}
              fieldLabel="Enter password"
              showPasswordToggle={true}
          />
          <div className="form-group">
            <FullButton label="SIGN IN" primary onClick={handleSubmit} />
          </div>
          <div className="message-group">
            {error && <p className="error-message">{error}</p>}
            <div className="form-group" style={{ marginBottom: '5px', marginTop: '30px' }}>
              <span>Did you forget your password?</span>
              <button type="button" className="forgot-password" onClick={handleResetPassword}>
                Reset now
              </button>
            </div>
            <div className="form-group">
              <span>Don't have an account? </span>
              <button type="button" className="forgot-password" onClick={handleRedirectToSignUp}>
                Sign up
              </button>
            </div>
          </div>
        </form>
      </div>
  );
};
