import {axiosInstance} from "../api/axios";

export const CategoryService = {
  getCategories() {
    return axiosInstance.get("/api/categories/all");
  },
  updateTypes(types) {
    return axiosInstance.post("/api/categories/types/update",
        types);
  },
  getOccupations() {
    return axiosInstance.get("/api/categories/occupations/all");
  },
  updateOccupations(occupationIds) {
    return axiosInstance.post("/api/categories/occupations/update",
        occupationIds);
  },
  getAuthors() {
    return axiosInstance.get("/api/categories/authors/all");
  },
  updateAuthors(authorIds) {
    return axiosInstance.post("/api/categories/authors/update", authorIds);
  },
  getLaws() {
    return axiosInstance.get("/api/categories/laws/all")
  },
  updateLaws(lawIds) {
    return axiosInstance.post("/api/categories/laws/update", lawIds);
  },
  getKeywords() {
    return axiosInstance.get("/api/categories/keywords/all")
  },
  updateKeywords(keywords) {
    return axiosInstance.post("/api/categories/keywords/update", keywords);
  },
};
