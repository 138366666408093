import React, {useState} from 'react';
import { InputField } from './InputField';
import '../styles/Login.css';
import FullButton from './FullButton';

export const ResetPasswordForm = ({ onResetPassword, message, setMessage }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate password and confirmPassword before submitting
    if (!validatePassword(password)) {
      setMessage('Password must be at least 6 characters long');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    setMessage('');
    onResetPassword(password);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  // Password validation function
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="form-title">Reset Password</h2>
          <InputField
              label="New Password:"
              type="password"
              value={password}
              onChange={handleChangePassword}
              fieldLabel="Enter new password"
          />
          {/* Add a new input for confirming the password */}
          <InputField
              label="Confirm Password:"
              type="password"
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              fieldLabel="Confirm new password"
          />
          <div className="form-group">
            <FullButton label="RESET PASSWORD" primary onClick={handleSubmit} />
          </div>
          <div className="message-group">
            {message.length > 0 && (
                <div className="error-list">
                  {message.map((error, i) => (
                      <div key={i} className="error-message">
                        {error}
                      </div>
                  ))}
                </div>
            )}
          </div>
        </form>
      </div>
  );
};
