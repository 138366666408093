import freeIcon from "../images/free_icon.png";
import proIcon from "../images/pro_icon.png";
import basicIcon from "../images/basic_icon.png";

export const getSubscriptionIcon = (subscription) => {
  switch (subscription) {
    case 'PRO':
      return { icon: proIcon, width: 28, height: 17 };
    case 'BASIC':
      return { icon: basicIcon, width: 38, height: 17 };
    case 'FREE':
    default:
      return { icon: freeIcon, width: 33, height: 17 };
  }
};