import React, {useState} from 'react';
import {
  FaCog,
  FaListAlt,
  FaTachometerAlt,
  FaUser,
  FaUserShield
} from 'react-icons/fa';
import '../styles/Sidebar.css';
import logo from "../images/infolex_logo_circle.png";
import {getSubscriptionIcon} from "../helpers/SubscriptionHelper";
import {RedirectButton} from "./RedirectButton";
import {getLawyer} from "../helpers/StorageHelper";
import {MdMenu} from "react-icons/md";

export const Sidebar = ({
  lawyer,
  subscription,
  isOpen,
  toggleSidebar,
  selectOption
}) => {
  const userProfileImage = null;
  const lawyerData = getLawyer;
  const firstName = lawyerData?.firstName || lawyer?.firstName || "";
  const lastName = lawyerData?.lastName || lawyer?.lastName || "";
  const role = lawyerData?.role || lawyer.user?.role?.type || "LAWYER";
  const subscriptionType = lawyerData?.subscriptionType
      || subscription.subscription?.type;
  const [sidebarSelectedOption, setSidebarSelectedOption] = useState(
      sessionStorage.getItem('sidebarSelectedOption') || 'Dashboard');
  const {icon, width, height} = getSubscriptionIcon(subscriptionType);

  const menuOptions = [
    {
      id: 'dashboard_nav',
      icon: <FaTachometerAlt/>,
      label: "Dashboard",
      url: "/dashboard"
    },
    {id: 'profile_nav', icon: <FaUser/>, label: "Profile", url: "/profile"},
    {
      id: 'preferences_nav',
      icon: <FaListAlt/>,
      label: "Preferences",
      url: "/category"
    },
    {
      id: 'settings_nav',
      icon: <FaCog/>,
      label: "Settings",
      url: "/settings"
    },
  ];

  const adminOptions = [
    {id: 'admin_nav', icon: <FaUserShield/>, label: "Admin", url: "/admin"},
    {
      id: 'adminDashboard_nav',
      icon: <FaTachometerAlt/>,
      label: "Dashboard",
      url: "/dashboard"
    },
    {
      id: 'adminProfile_nav',
      icon: <FaUser/>,
      label: "Profile",
      url: "/profile"
    },
    {
      id: 'adminPreferences_nav',
      icon: <FaListAlt/>,
      label: "Preferences",
      url: "/category"
    },
    {
      id: 'adminSettings_nav',
      icon: <FaCog/>,
      label: "Settings",
      url: "/settings"
    },
  ];

  return (
      <div>
        <MdMenu className={`toggle-button ${isOpen ? '' : 'open'}`}
                onClick={toggleSidebar}/>
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <div className={`person ${isOpen ? '' : 'hidden'}`}>
            <img
                src={logo}
                alt={`logo`}
                className="person-image"
            />
            <p className={`person-name ${isOpen ? ''
                : 'hidden'}`}>{firstName} {lastName}</p>
            <img
                src={icon}
                alt={`${subscriptionType} subscription`}
                height={height}
                width={width}
            />
          </div>
          <ul className="menu-options">
            {(role === 'ADMIN' ? adminOptions : menuOptions).map(
                (option) => (
                    <li key={option.id} id={option.id}
                        className={sidebarSelectedOption === option.label
                            ? 'selected' : ''}
                        onClick={() => selectOption(option.label)}>
                      <a href={option.url}>
                        <span className={`menu-icon ${isOpen ? ''
                            : 'hidden'}`}>{option.icon}</span>
                        {isOpen && option.label}
                      </a>
                    </li>
                ))}
          </ul>
          <div className={`upgrade-plan ${isOpen ? '' : 'hidden'}`}>
            <RedirectButton to="/subscription" onClick={() => {
              selectOption("plan");
              setSidebarSelectedOption("plan");
            }}>{subscriptionType === 'FREE' ? 'Upgrade'
                : 'View'} plan</RedirectButton>
          </div>
        </div>
      </div>
  );
};