import React from 'react';
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export const CustomToggleButton = ({ options, groupName, handleChange }) => {
  return (
      <ButtonGroup toggle>
        {options.map((option) => (
            <ToggleButton
                key={option.id}
                id={`radio-${option.name}`}
                type="radio"
                name={groupName}
                variant={'outline-dark'}
                value={option.value}
                checked={option.value}
                onChange={() => handleChange(option.id === 2)}
            >
              {option.name}
            </ToggleButton>
        ))}
      </ButtonGroup>
  );
};