import React from 'react';
import Box from "./Box";
import '../styles/Column.css'

export const Column = ({ boxes }) => {
  return (
      <div id="column_boxes" className="column">
        <div className="row">
          {boxes.map((box, index) => (
              <Box key={index} title={box.title}>
                {box.content}
              </Box>
          ))}
        </div>
      </div>
  );
};
