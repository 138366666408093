import {useEffect, useState} from "react";
import {setAuthToken} from "./axios";
import {LawyerService} from "../services/LawyerService";
import {useNavigate} from "react-router-dom";

export function useAdmin() {
  const navigate = useNavigate();
  const [lawyers, setLawyers] = useState([]);

  useEffect(() => {
    setAuthToken();

    LawyerService.getAllLawyers()
    .then(response => {
      setLawyers(response.data)
    })
    .catch(() => {
      navigate('/login');
    })

  }, [navigate]);

  return {lawyers};
}
