import React, {useEffect, useState} from "react";
import privacyNotice from "../files/privacy_notice.pdf";
import termsAndConditions from "../files/terms_and_conditions.pdf";
import "../styles/Document.css";
import {useNavigate, useParams} from "react-router-dom";

export const Document = () => {
  const [filePath, setFilePath] = useState("");
  const [error, setError] = useState("");
  const { fileName } = useParams();
  const navigate = useNavigate();

  const pdfFiles = {
    'privacy-notice': privacyNotice,
    'terms-and-conditions': termsAndConditions,
  };

  useEffect(() => {
    if (fileName && pdfFiles[fileName]) {
      setFilePath(pdfFiles[fileName]);
    } else {
      setError("File not found")
      navigate("/")
    }
  }, []);

  return (
      <div className="document">
        <div className="document-form">
          {error ? (
              <div className="error-message">{error}</div>
          ) : (
              filePath && (
                  <iframe
                      title="Document Viewer"
                      src={`${filePath}#toolbar=0`}
                      className="pdf-frame"
                  />
              ))}
        </div>
      </div>
  );
};
