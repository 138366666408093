import React from 'react';
import {ForgetPasswordForm} from "../components/ForgetPasswordForm";
import {AuthService} from "../services/AuthService";

export const ForgetPassword = () => {
  const [message, setMessage] = React.useState('');

  const handleForgetPassword = (email) => {
    AuthService.forgetPassword(email)
    .then((response) => {
      setMessage(response.data)
    })
    .catch((currentError) => {
      const errors = currentError.response?.data
          || 'An error occurred during reset password.';
      setMessage(errors)
    })
  }

  return (
      <div>
        <ForgetPasswordForm onForgetPassword={handleForgetPassword}
                            message={message}
                            setMessage={setMessage}/>
      </div>
  );
};