import {useEffect, useState} from "react";
import {setAuthToken} from "./axios";
import {LawyerService} from "../services/LawyerService";
import {NotificationService} from "../services/NotificationService";

export function useProfile() {
  const [lawyer, setLawyer] = useState({});
  const [notification, setNotification] = useState({});

  useEffect(() => {

    setAuthToken();

    LawyerService.getLawyer()
    .then(response => {
      setLawyer(response.data)
    })
    .catch(error => {
      console.error('Error fetching lawyer:', error);
    })

    NotificationService.getNotification()
    .then(response => {
      setNotification(response.data)
    })
    .catch(error => {
      console.error('Error fetching notification:', error);
    })
  }, []);

  return {lawyer, notification};
}