import React from 'react';
import '../styles/ProfileForm.css';
import FullButton from "./FullButton";
import {OccupationForm} from "./OccupationForm";
import {AuthorForm} from "./AuthorForm";
import {LawForm} from "./LawForm";
import {KeywordForm} from "./KeywordForm";
import {TypeForm} from "./TypeForm";

const CategoryForm = ({
  categories,
  occupations,
  authors,
  laws,
  keywords,
  onSaveTypes,
  onSaveOccupation,
  onSaveAuthor,
  onSaveLaw,
  onSaveKeyword,
  showTypesForm,
  showOccupationForm,
  showAuthorForm,
  showLawForm,
  showKeywordForm,
  onSaveButtonClick,
}) => {
  return (
      <div>
        <div className="edit-profile-form">
          {showTypesForm ? (
              <TypeForm
                  selectedTypes={categories.types}
                  onSavesTypes={onSaveTypes}/>
          ) : null}
          {showOccupationForm ? (
              <OccupationForm
                  occupationData={occupations}
                  selectedOccupations={categories.occupations}
                  onSaveOccupation={onSaveOccupation}/>
          ) : null}
          {showAuthorForm ? (
              <AuthorForm
                  authorData={authors}
                  selectedAuthors={categories.authors}
                  onSaveAuthor={onSaveAuthor}/>
          ) : null}
          {showLawForm ? (
              <LawForm
                  lawData={laws}
                  selectedLaws={categories.laws}
                  onSaveLaw={onSaveLaw}/>
          ) : null}
          {showKeywordForm ? (
              <KeywordForm
                  keywordData={keywords}
                  selectedKeywords={categories.keywords}
                  onSaveKeyword={onSaveKeyword}/>
          ) : null}
        </div>
        <div id="profile_form_save_btn" className="button-container">
          <FullButton
              label="Save"
              primary
              onClick={onSaveButtonClick} // Call the callback here
              className="full-button" // Add this line to apply the CSS styles
          />
        </div>
      </div>
  );
};

export default CategoryForm;
