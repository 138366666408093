import React from 'react';
import '../styles/ToggleSwitch.css';
import {CustomToggleButton} from "./CustomToggleButton";

export const ToggleSwitch = ({
  setIsYearly,
  setIsRecurring,
  periodOptions,
  timeOptions,
}) => {
  return (
      <div className="toggle-switch">
        <div className="toggle-switch-container">
          <div className="form-row">
            <div className="form-column">
              <CustomToggleButton
                  options={periodOptions}
                  groupName="periodOptions"
                  handleChange={setIsYearly}
              />
              <CustomToggleButton
                  options={timeOptions}
                  groupName="timeOptions"
                  handleChange={setIsRecurring}
              />
            </div>
          </div>
        </div>
      </div>
  );
};