import React from 'react';
import '../styles/Pricing.css';
import {CustomToggleButton} from "./CustomToggleButton";
import {SubscriptionForm} from "./SubscriptionForm";

const PricingContainer = ({
  subscriptionData,
  isYearly,
  trialPeriod,
  handleToggle,
  handleSelectPlan
}) => {
  const periodOptions = [
    {id: 1, name: 'Monthly', value: !isYearly},
    {id: 2, name: 'Annually', value: isYearly}
  ];

  return (
      <div id="pricing" className="pricing-container">
        <h3>Try out the best of Info-Lex for free</h3>
        <div className="toggle-container">
          <CustomToggleButton
              options={periodOptions}
              groupName="periodOptions"
              handleChange={handleToggle}
          />
        </div>
        <div className="plans-container">
          <SubscriptionForm pricingPlans={subscriptionData}
                            trialPeriod={trialPeriod}
                            isMonthly={isYearly}
                            isRecurring={false}
                            onSelectPlan={handleSelectPlan}/>
        </div>
      </div>
  );
};

export default PricingContainer;
