import axios from 'axios';
import {TokenService} from "../services/TokenService";

const BASE_URL = 'https://infolex.onrender.com';

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Function to set the JWT token in the request headers
export const setAuthToken = () => {
  const token = TokenService.getToken();

  if (token) {
    // If a token is available, set it in the 'Authorization' header
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // If no token is available, remove the 'Authorization' header
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};
