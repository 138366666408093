import React, {useEffect, useState} from 'react';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js/pure";
import {PaymentService} from "../services/PaymentService";

export const Checkout = ({subscriptionId, stripeId, isRecurring}) => {

  const [clientSecret, setClientSecret] = useState('');
  const stripePromise = loadStripe(stripeId);
  const hostname = window.location.hostname;
  const port = window.location.port;
  const protocol = window.location.protocol;
  const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

  useEffect(() => {
    PaymentService.createCheckoutSession(subscriptionId, isRecurring,`${baseUrl}/subscription`)
    .then((res) => setClientSecret(res.data.clientSecret));
  }, [subscriptionId, isRecurring]);

  return (
      <div id="checkout">
        {clientSecret && (
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                subscriptionId={subscriptionId}
                options={{
                  clientSecret
                }}
            >
              <EmbeddedCheckout/>
            </EmbeddedCheckoutProvider>
        )}
      </div>
  );
};