import { format, parseISO } from 'date-fns';

export default class DateHelper {
  static formatDate(date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  }

  // Convert Unix timestamp to formatted date string (e.g., "DD/MM/YYYY")
  static formatDateFromUnixTimestamp(unixTimestamp) {
    const milliseconds = unixTimestamp * 1000;
    const date = new Date(milliseconds);
    return format(date, 'dd/MM/yyyy');
  }

  // Parse ISO date string to formatted date string (e.g., "DD/MM/YYYY")
  static formatDateFromISO(dateString) {
    const parsedDate = parseISO(dateString);
    return format(parsedDate, 'dd/MM/yyyy');
  }

  static getToday() {
    return new Date();
  }

  static getTomorrow() {
    const today = this.getToday();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  }
}