import React, {useEffect, useState} from 'react';
import '../styles/Subscription.css';
import {SubscriptionForm} from "../components/SubscriptionForm";
import {SubscriptionService} from "../services/SubscriptionService";
import {useSubscription} from "../api/useSubscription";
import {ToggleSwitch} from "../components/ToggleSwitch";
import {CheckoutModal} from "../components/CheckoutModal";
import {SettingsService} from "../services/SettingsService";
import {PaymentService} from "../services/PaymentService";
import {StatusModal} from "../components/StatusModal";
import {useNavigate} from "react-router-dom";
import {getLawyer} from "../helpers/StorageHelper";

export const Subscription = () => {
  const navigate = useNavigate();
  const {subscription} = useSubscription();
  const lawyerData = getLawyer;
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [isYearly, setIsYearly] = useState(lawyerData.subscription?.subscription?.period === "YEAR");
  const [isRecurring, setIsRecurring] = useState(lawyerData.subscription?.subscription?.recurring === true);
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [stripeId, setStripeId] = useState('');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);

  const periodOptions = [
    { id: 1, name: 'Monthly', value: !isYearly },
    { id: 2, name: 'Annually', value: isYearly }
  ];

  const timeOptions = [
    { id: 1, name: 'One time', value: !isRecurring },
    { id: 2, name: 'Recurring', value: isRecurring }
  ];

  useEffect(() => {
    SubscriptionService.getAllSubscriptionsByPeriod(isYearly ? 'year' : 'month')
    .then((res) => {
      setSubscriptionData(res.data);
    })
    .catch(error => {
      console.error('Error fetching subscriptions:', error);
    });
    SettingsService.getPrivateSettings()
    .then((res) => {
      setStripeId(res.data?.stripeId);
    })
    .catch(error => {
      console.error('Error fetching settings:', error);
    });
  }, [isYearly]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const paymentId = urlParams.get('payment_id');

    if (sessionId) {
      PaymentService.getSessionStatus(sessionId, paymentId)
      .then((res) => {
        setPaymentStatus(res.data.status);
        setOpenStatusModal(true)
      });
    }
  }, []);

  const handleSelectPlan = (id) => {
    if (id.includes("free")) {
      setSelectedSubscriptionId(id)
    } else {
      setSelectedSubscriptionId(id);
      setOpenCheckoutModal(true);
    }
  };

  const handleCancelPlan = (subscriptionRef) => {
    if (subscriptionRef) {
      SubscriptionService.cancelSubscription(subscriptionRef).then(() => {
      } );
    }
  };

  const handleCloseModal = () => {
    setOpenCheckoutModal(false);
    setOpenStatusModal(false)
    navigate('/dashboard')
  };

  return (
      <div className="subscription">
        <div id="subscription" className="subscription-container">
          <h1>Subscriptions</h1>
          <div className="toggle-container">
            <ToggleSwitch
                isYearly={isYearly}
                setIsYearly={setIsYearly}
                isRecurring={isRecurring}
                setIsRecurring={setIsRecurring}
                periodOptions={periodOptions}
                timeOptions={timeOptions}
            />
          </div>
          <SubscriptionForm pricingPlans={subscriptionData}
                            currentSubscription={subscription}
                            isMonthly={isYearly}
                            isRecurring={isRecurring}
                            onSelectPlan={handleSelectPlan}
                            onCancelPlan={handleCancelPlan}/>
        </div>
        <CheckoutModal subscriptionId={selectedSubscriptionId}
                       stripeId={stripeId}
                       isRecurring={isRecurring} isOpen={openCheckoutModal}
                       onClose={handleCloseModal}>
        </CheckoutModal>
        {subscription && (
            <StatusModal isOpen={openStatusModal}
                         currentSubscription={subscription}
                         paymentSuccessful={paymentStatus === 'complete'}
                         onClose={handleCloseModal}>
            </StatusModal>
        )}
      </div>
  );
};
