import {axiosInstance} from "../api/axios";

export const AuthService = {

  login(email, password) {
    return axiosInstance.post('/api/auth/login', { email, password })
  },

  logout() {
    return axiosInstance.post('/api/auth/logout')
  },

  register(email, password, planId) {
    return axiosInstance.post('/api/auth/register', { email, password , planId})
  },

  forgetPassword(email) {
    return axiosInstance.post('api/auth/forget-password', {email})
  },

  resetPassword(email, password, token) {
    return axiosInstance.post('api/auth/reset-password', {email, password, token})
  }
};
