import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import {Checkout} from "./Checkout";

export const CheckoutModal = ({ subscriptionId, stripeId, isRecurring, isOpen, onClose }) => {
  return (
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Checkout subscriptionId={subscriptionId} stripeId={stripeId} isRecurring={isRecurring}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
};