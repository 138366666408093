import React from 'react';
import '../styles/PricingPlan.css';
import FullButton from "./FullButton";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import DateHelper from "../helpers/DateHelper";

export const PricingPlan = ({
  id,
  name,
  price,
  limit,
  trialPeriod,
  currentSubscription,
  isMonthly,
  isRecurring,
  onSelectPlan,
  onCancelPlan,
}) => (
    <div className="pricing-plan">
      <div className={`pricing-plan-content ${currentSubscription?.subscription?.productId === id ? 'current' : ''}`}>
        <div className="pricing-plan-header">
          <div className="pricing-plan-name">{name}</div>
        </div>
        <hr className="pricing-plan-divider"/>
        <div className="pricing-plan-price">
          <span className="pricing-plan-price-value">€{price / 100}</span>
          <span className="pricing-plan-price-unit">/ {isMonthly ? 'month'
              : 'year'}</span>
        </div>
        <span className="pricing-plan-feature-options">{isRecurring
            ? 'Recurring' : 'One time'}</span>
        <hr className="pricing-plan-divider"/>
        <div className="pricing-plan-feature">
          <span
              className="pricing-plan-feature-text"><IoMdCheckmarkCircleOutline
              className="pricing-plan-icon"/> {limit} Occupation choice/s</span>
          <span
              className="pricing-plan-feature-text"><IoMdCheckmarkCircleOutline
              className="pricing-plan-icon"/> {limit} Author choice/s</span>
          <span
              className="pricing-plan-feature-text"><IoMdCheckmarkCircleOutline
              className="pricing-plan-icon"/> {limit} Law choice/s</span>
          {name === 'PRO' && (
              <span
                  className="pricing-plan-feature-text"><IoMdCheckmarkCircleOutline
                  className="pricing-plan-icon"/> {limit} Keyword choice/s</span>
          )}
        </div>
        {currentSubscription?.subscription?.productId === id ? (
            <div className="pricing-plan-button">
              {name !== 'FREE' && (
                  <>
                    {isRecurring && (
                        <FullButton label="Cancel" cancel onClick={() => onCancelPlan(currentSubscription?.subscriptionRef)} />
                    )}
                    {currentSubscription?.expirationDate && (
                        <span>Expires on {DateHelper.formatDateFromUnixTimestamp(currentSubscription.expirationDate)}</span>
                    )}
                  </>
              )}
            </div>
        ) : (
            <FullButton
                label={
                  name === 'FREE'
                      ? `Select`
                      : `${trialPeriod ? `Start a ${trialPeriod}-day free trial` : 'Select'}`
                }
                primary
                onClick={() => onSelectPlan(id)}
            />
        )}
      </div>
    </div>
);