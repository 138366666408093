import React, {useMemo} from 'react';
import Table from "./Table";
import FullButton from "./FullButton";

export const LawyerList = ({lawyerData, onClickNewUser, onSelectUser}) => {

  const flattenedData = lawyerData.map(lawyer => ({
    id: lawyer?.lawyerId,
    user_id: lawyer?.user?.userId,
    first_name: lawyer?.firstName,
    last_name: lawyer?.lastName,
    email: lawyer?.user?.email,
    role: lawyer?.user?.role?.type,
    subscription: lawyer?.user?.subscription?.type,
  }));

  const columns = useMemo(() => [
    {
      field: 'user_id',
      headerName: 'User ID',
      width: 80,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 100,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 120,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 100,
    },
    {
      field: 'subscription',
      headerName: 'Subscription',
      width: 100,
    },
  ], []);

  return (
      <div className="form-container">
        <div className="section-title">
          <div className="form-row">
            <div className="form-column">
              <h2>All Users</h2>
              <h4>These are all the users</h4>
            </div>
            <div className="form-column-button">
              <FullButton label="New User" primary onClick={onClickNewUser}/>
            </div>
          </div>
        </div>
        <div className="table-container">
          <Table columns={columns} data={flattenedData} onSelectedOption={onSelectUser}/>
        </div>
      </div>
  );
};
