import {useEffect, useState} from "react";
import {setAuthToken} from "./axios";
import {CategoryService} from "../services/CategoryService";

export function useCategory() {
  const [categories, setCategories] = useState({});
  const [occupations, setOccupations] = useState({});
  const [authors, setAuthors] = useState({});
  const [laws, setLaws] = useState({});
  const [keywords, setKeywords] = useState({});

  useEffect(() => {
    setAuthToken();

    CategoryService.getCategories()
    .then((response) => {
      setCategories(response.data);
    })
    .catch((error) => {
      console.error("Error fetching categories:", error);
    });

    CategoryService.getOccupations()
    .then((response) => {
      setOccupations(response.data);
    })
    .catch((error) => {
      console.error("Error fetching occupations:", error);
    });

    CategoryService.getAuthors()
    .then((response) => {
      setAuthors(response.data);
    })
    .catch((error) => {
      console.error("Error fetching keywords:", error);
    });

    CategoryService.getLaws()
    .then((response) => {
      setLaws(response.data)
    })
    .catch((error) => {
      console.error("Error fetching laws:", error);
    });

    CategoryService.getKeywords()
    .then((response) => {
      setKeywords(response.data)
    })
    .catch((error) => {
      console.error("Error fetching keywords:", error);
    });
  }, []);

  return {categories, occupations, authors, laws, keywords};
}
