import {useEffect, useState} from 'react';
import {DashboardService} from "../services/DashboardService";
import {setAuthToken} from "./axios";

// Custom hook to handle Dashboard data fetching and state management
export function useDashboard() {
  const [boxesData, setBoxesData] = useState({});

  useEffect(() => {
    // Fetch the data from the API when the component mounts
    setAuthToken();

    // Get count of all legislations, regulations, decisions, and directives modified today
    DashboardService.getCountAllToday()
    .then(response => {
      setBoxesData(response.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });

  }, []);

  return {boxesData};
}