import React from "react";
import "../styles/home.css";
import {RedirectButton} from "../components/RedirectButton";
import laptopAndPhoneMockup from "../images/laptop_and_phone_mockup.png";
import laptopAndPhoneMockup2 from "../images/laptop_and_phone_mockup_2.png";

export const Home = () => {
  return (
      <div className="homepage">
        <div className="home-container">
          <div className="home-content">
            <h1>
              Navigate the legal
              <br/>
              landscape with
              <br/>
              confidence
            </h1>
            <div className="button-container">
              <RedirectButton to="/login" primary={true}>
                Sign In
              </RedirectButton>
              <RedirectButton to="/pricing">Register</RedirectButton>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="section-content">
            <div className="text-content">
              <h2>Stay ahead with us</h2>
              <p>
                Welcome to our platform, made especially for lawyers and
                advocates.
                Being up-to-date is really important for practicing law in the
                European Union (EU),
                and we're here to make it easy for you.
              </p>
              <p>
                With only a two-month window provided by EU law to appeal any
                legislation, being informed and proactive is vital to
                safeguarding your clients' rights and interests.
              </p>
              <p>
                Sign up now for free for the latest updates on EU laws,
                including regulations, directives, and decisions. Stay ahead to
                protect your clients' interests by not missing critical
                updates, enabling you to provide top-notch representation and
                advice.
              </p>
            </div>
          </div>
          <div className="section-image">
            <img src={laptopAndPhoneMockup} alt="Legislation"/>
          </div>
        </section>
        <section className="section reverse">
          <div className="section-content">
            <div className="text-content">
              <h2>Stay informed with us</h2>
              <p>
                Our platform seamlessly keeps legal professionals informed about
                the latest EU legislation. Users gain access to a centralized
                hub upon registration, where they can stay updated on
                regulations, directives, and decisions.
              </p>
              <p>With our system continuously monitoring and aggregating
                relevant information, users receive timely notifications by
                email about legislative changes. The user-friendly interface
                allows for effortless navigation through updates, enabling quick
                assessment of implications on cases.
              </p>
              <p>Utilizing our platform enables lawyers and advocates to
                efficiently stay informed, providing proactive and informed
                counsel
                to clients.
              </p>
            </div>
          </div>
          <div className="section-image">
            <img src={laptopAndPhoneMockup2} alt="Service"/>
          </div>
        </section>
        {/*<section className="section reverse centered">*/}
        {/*  <div className="section-content">*/}
        {/*    <h2>Another Section</h2>*/}
        {/*    <p>*/}
        {/*      This is another section providing information about your service*/}
        {/*      or*/}
        {/*      any other relevant content.*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</section>*/}
      </div>
  );
};
