import React, { useState } from 'react';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { AuthService } from '../services/AuthService';
import {useNavigate} from "react-router-dom";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState([]);

  const handleResetPassword = (password) => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('token');

    AuthService.resetPassword(email, password, token)
    .then((response) => {
     handleResetPasswordSuccess(response?.data?.errors)
    })
    .catch((currentError) => {
      const errors = currentError.response?.data?.errors ||
          ['An error occurred during password reset.'];
      handleResetPasswordError(errors)
    });
  };

  const handleResetPasswordSuccess = () => {
    navigate('/login')
  };

  const handleResetPasswordError = (errors) => {
    setMessage(errors);
  };

  const handleFormErrors = (errorMessage) => {
    setMessage([errorMessage]);
  };

  return (
      <div>
        <ResetPasswordForm
            onResetPassword={handleResetPassword}
            message={message}
            setMessage={handleFormErrors}
        />
      </div>
  );
};
