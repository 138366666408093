import React, {useEffect, useState} from 'react';
import {RegisterForm} from '../components/RegisterForm';
import {useNavigate} from "react-router-dom";
import {AuthService} from "../services/AuthService";
import {TokenService} from "../services/TokenService";
import {clearStorageValues, enableTourGuides} from "../helpers/StorageHelper";

export const Register = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [planId, setPlanId] = useState("")

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const planId = urlParams.get('planId');
    setPlanId(planId)
  }, []);

  const handleRegister = (email, password) => {
    AuthService.register(email, password, planId)
    .then((response) => {
      const {token} = response.data;
      if (token) {
        TokenService.setToken(token);
        handleRegisterSuccess();
      }
    })
    .catch((currentError) => {
      const errors =
          currentError.response?.data?.errors
          || ['An error occurred during login.'];
      handleRegisterError(errors);
    });
  };

  const handleRegisterSuccess = () => {
    clearStorageValues();
    enableTourGuides();
    navigate('/dashboard')
  };

  const handleRegisterError = (errors) => {
    setErrors(errors);
  };

  const handleFormError = (errorMessage) => {
    setErrors([errorMessage]);
  };

  const handleRedirectToSignIn = () => {
    navigate('/login');
  }

  return (
      <div>
        <RegisterForm onRegister={handleRegister}
                      onRedirectToSignIn={handleRedirectToSignIn}
                      errors={errors} setErrors={handleFormError}/>
      </div>
  );
};
