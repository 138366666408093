import React from 'react';
import '../styles/GenericForm.css';
import FullButton from "./FullButton";

export const AccountForm = ({onDeleteAccount}) => {

  return (
      <div className="form-container">
        <div className="section-title">
          <h2>Account Settings</h2>
        </div>
        <div className="form-row">
          <div className="form-column">
            <label className="form-label">Delete Account:</label>
            <h4>Delete your account and all your source data. This is
              irreversible.</h4>
            <div style={{width: "50%"}}>
              <FullButton label="Delete Account" cancel
                          onClick={onDeleteAccount}/>
            </div>
          </div>
        </div>
      </div>
  );
};