import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {IoShieldCheckmarkOutline} from 'react-icons/io5';
import {SlClose} from "react-icons/sl";
import '../styles/StatusModal.css';

export const StatusModal = ({
  isOpen,
  onClose,
  customerEmail,
  paymentSuccessful,
  currentSubscription
}) => {
  return (
      <Modal show={isOpen} onHide={onClose} className="status-modal">
        <Modal.Header closeButton>
          <Modal.Title>Payment Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <section>
              <div className="status-container">
                {paymentSuccessful ? (
                    <>
                      <IoShieldCheckmarkOutline size="130px"
                                                className="status-icon-success"/>
                      <h4>Your Payment was successful!</h4>
                      <span>A confirmation email was send to you.</span>
                    </>
                ) : (
                    <>
                      <SlClose size="120px" className="status-icon-failure"/>
                      <h4>Your Payment has failed</h4>
                      <span>Please try again.</span>
                    </>
                )}
              </div>
            </section>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
};