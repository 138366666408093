import React from 'react';
import '../styles/SelectedOption.css';
import { FaTimes } from 'react-icons/fa';

const SelectedOption = ({ option, onRemove }) => {
  return (
      <div className="selected-option">
        <div className="selected-label">
        {option.name}
        </div>
        <button className="remove-option" onClick={() => onRemove(option)}>
          <FaTimes className="remove-icon"/>
        </button>
      </div>
  );
};

export default SelectedOption;
