import React, {useEffect, useState} from 'react';
import {Column} from "../components/Column";
import '../styles/Dashboard.css';
import {useDashboard} from "../api/useDashboard";
import DocumentTable from "../components/DocumentTable";
import DateHelper from "../helpers/DateHelper";
import {DashboardService} from "../services/DashboardService";
import TourModal from "../components/TourModal";
import {getDashboardTourSteps} from "../steps/TourSteps";

export const Dashboard = () => {
  const {boxesData} = useDashboard();
  const [legalDocuments, setLegalDocuments] = useState([]);
  const [steps, setSteps] = useState([]);
  const [startTour, setStartTour] = useState(sessionStorage.getItem('enableDashboardTour'));

  useEffect(() => {
    const todayDate = DateHelper.formatDate(DateHelper.getToday());
    const tomorrowDate = DateHelper.formatDate(DateHelper.getTomorrow());

    DashboardService.getLegalDocuments(1, 20, "en", todayDate, tomorrowDate)
    .then(response => {
      setLegalDocuments(response.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });

  }, [])

  useEffect(() => {
    setSteps(getDashboardTourSteps);
  }, []);

  const rowData = [
    {title: 'Regulations today', content: boxesData.regulations || 0},
    {title: 'Decisions today', content: boxesData.decisions || 0},
    {title: 'Directives today', content: boxesData.directives || 0},
  ];

  return (
      <div className="dashboard">
        <TourModal steps={steps} page='Dashboard' run={startTour} setRun={setStartTour}/>
        <div id="dashboard" className="dashboard-container">
        <h1>Dashboard</h1>
        <Column boxes={rowData}/>
        <div className="data-grid-and-summary">
          <DocumentTable documentData={legalDocuments}/>
        </div>
        </div>
      </div>
  );
};
