import {axiosInstance, setAuthToken} from "../api/axios";

export const LawyerService = {
  getLawyer() {
    setAuthToken();
    return axiosInstance.get('/api/profile/lawyer/info');
  },

  addLawyer(lawyer) {
    setAuthToken();
    return axiosInstance.post('/api/lawyer/admin/add', {lawyer});
  },

  updateLawyer(userId, lawyer) {
    setAuthToken();
    return axiosInstance.put(`/api/lawyer/admin/update/${userId}`, lawyer);
  },

  updateLawyerProfile(email, firstName, lastName) {
    setAuthToken();
    return axiosInstance.post('/api/profile/lawyer/update', {email, firstName, lastName});
  },

  getAllLawyers() {
    setAuthToken();
    return axiosInstance.get('/api/lawyer/admin/all');
  },

  getLawyerByUserId(userId) {
    setAuthToken();
    return axiosInstance.get(`/api/lawyer/admin/info/${userId}`);
  }
};