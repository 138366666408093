import React from 'react';
import '../styles/ProfileForm.css';
import {AccountForm} from "./AccountForm";

const SettingsForm = ({
  showAccountForm,
  onDeleteAccount,
}) => {
  return (
      <div>
        <div className="edit-profile-form">
          {showAccountForm ? (
              <AccountForm onDeleteAccount={onDeleteAccount}/>
          ) : null}
        </div>
      </div>
  );
};

export default SettingsForm;
