import React from "react";
import { Link } from "react-router-dom";

export const RedirectButton = ({ to, children, primary, onClick }) => {
  const buttonClassName = primary ? "btn btn-primary" : "btn btn-secondary";

  return (
      <Link to={to} className="nav-link">
        <button onClick={onClick} className={buttonClassName}>{children}</button>
      </Link>
  );
};
