import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import '../styles/Table.css';
import {InputField} from "./InputField";

export default function Table({ columns, data, onSelectedOption }) {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const filteredRows = data.filter((row) => {
      return columns.some((column) => {
        const value = row[column.field] || '';
        return value.toString().toLowerCase().includes(
            searchTerm.toLowerCase()
        );
      });
    });
    setFilteredData(filteredRows);
  }, [searchTerm, data, columns]);

  return (
      <div>
        <div className="form-row">
          <div className="form-column">
            <InputField
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fieldLabel="Search..."
            />
          </div>
        </div>
        <div className="data-grid-container">
          <DataGrid
              columns={columns.map((col) => ({
                ...col,
                renderCell: (params) =>
                    col.isLink ? (
                        <a
                            href={params.row[col.field]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          {col.icon}
                        </a>
                    ) : (
                        <div>{params.value}</div>
                    ),
              }))}
              rows={filteredData}
              getRowId={(row) => row?.id || row?.reference}
              onRowClick={onSelectedOption}
          />
        </div>
      </div>
  );
}