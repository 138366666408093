import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Login} from './pages/Login';
import {Home} from "./pages/Home";
import {ForgetPassword} from "./pages/ForgetPassword";
import {Register} from "./pages/Register";
import {ResetPassword} from "./pages/ResetPassword";
import {MainLayout} from "./layouts/MainLayout";
import {DashboardLayout} from "./layouts/DashboardLayout";
import {Dashboard} from "./pages/Dashboard";
import {Profile} from "./pages/Profile";
import {Category} from "./pages/Category";
import {Admin} from "./pages/Admin";
import {Subscription} from "./pages/Subscription";
import {Pricing} from "./pages/Pricing";
import {Settings} from "./pages/Settings";
import {Document} from "./pages/Document";

function App() {
  return (
      <Router>
        <Routes>
          <Route element={<MainLayout/>}>
            <Route path="/" element={<Home/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/reset" element={<ResetPassword/>}/>
            <Route path="/forget" element={<ForgetPassword/>}/>
            <Route path="/docs/:fileName" element={<Document/>}/>
          </Route>

          <Route element={<DashboardLayout/>}>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/category" element={<Category/>}/>
            <Route path="/admin" element={<Admin/>}/>
            <Route path="/subscription" element={<Subscription/>}/>
            <Route path="/settings" element={<Settings/>}/>
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
