import React from 'react';
import '../styles/PricingPlan.css';
import {PricingPlan} from "./PricingPlan";

export const SubscriptionForm = ({
  pricingPlans,
  trialPeriod,
  currentSubscription,
  isMonthly,
  isRecurring,
  onSelectPlan,
  onCancelPlan,
}) => {

  return (
      <div className="subscription-form">
        <div className="subscription-form-grid">
          {pricingPlans && pricingPlans
          .filter(plan => plan?.recurring === isRecurring)
          .map((plan) => (
              <div className="subscription-form-column"
                   key={plan?.productId}>
                <PricingPlan
                    id={plan?.productId}
                    name={plan?.type}
                    price={plan?.price}
                    features={plan?.features}
                    limit={plan?.preferencesLimit}
                    trialPeriod={trialPeriod}
                    isMonthly={!isMonthly}
                    isRecurring={isRecurring}
                    currentSubscription={currentSubscription}
                    onSelectPlan={onSelectPlan}
                    onCancelPlan={onCancelPlan}
                />
              </div>
          ))}
        </div>
      </div>
  );
}