import React, {useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Category.css';
import {useCategory} from '../api/useCategory';
import NavButtons from '../components/NavButtons';
import {CategoryService} from '../services/CategoryService';
import ToastHelper from '../helpers/ToastHelper';
import CategoryForm from "../components/CategoryForm";
import {getPreferencesTourSteps} from "../steps/TourSteps";
import TourModal from "../components/TourModal";

export const Category = () => {
  const {categories, occupations, authors, laws, keywords} = useCategory();
  const labels = ['By Type', 'By Occupation', 'By Author', 'By Law', 'By Keyword'];
  const [activeForm, setActiveForm] = useState(labels[0]);
  const [categoryData, setCategoryData] = useState({});
  const [occupationData, setOccupationData] = useState({});
  const [authorData, setAuthorData] = useState({});
  const [lawData, setLawData] = useState({});
  const [keywordData, setKeywordData] = useState({});
  const [tourStepIndex, setTourStepIndex] = useState(0);
  const [startTour, setStartTour] = useState(sessionStorage.getItem('enablePreferencesTour'));
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (categories) {
      setCategoryData({
        occupations: categories?.occupations,
        authors: categories?.authors,
        laws: categories?.laws,
        keywords: categories?.keywords,
        types: {
          hasRegulations: categories?.hasRegulations,
          hasDecisions: categories?.hasDecisions,
          hasDirectives: categories?.hasDirectives
        }
      });
    }
    if (occupations) {
      setOccupationData({
        occupations
      });
    }

    if (authors) {
      setAuthorData({
        authors
      });
    }

    if (laws) {
      setLawData({
        laws
      });
    }

    if (keywords) {
      setKeywordData({
        keywords
      });
    }
  }, [categories, occupations, authors, laws, keywords]);

  useEffect(() => {
    setSteps(getPreferencesTourSteps);
  }, []);

  const handleTypesSave = (types) => {
    CategoryService.updateTypes(types)
    .then(() => {
      ToastHelper.success('Types are successfully updated');
    })
    .catch(() => {
      ToastHelper.error('Oh No! Something went wrong');
    });
  };

  const handleOccupationsSave = (occupationIds) => {
    CategoryService.updateOccupations(occupationIds)
    .then(() => {
      ToastHelper.success('Occupations are successfully updated');
    })
    .catch(() => {
      ToastHelper.error('Oh No! Something went wrong');
    });
  };

  const handleAuthorsSave = (authorIds) => {
    CategoryService.updateAuthors(authorIds)
    .then(() => {
      ToastHelper.success('Authors are successfully updated');
    })
    .catch(() => {
      ToastHelper.error('Oh No! Something went wrong');
    });
  };

  const handleLawsSave = (lawIds) => {
    CategoryService.updateLaws(lawIds)
    .then(() => {
      ToastHelper.success('Laws are successfully updated');
    })
    .catch(() => {
      ToastHelper.error('Oh No! Something went wrong');
    });
  };

  const handleKeywordsSave = (keywords) => {
    CategoryService.updateKeywords(keywords)
    .then(() => {
      ToastHelper.success('Keywords are successfully updated');
    })
    .catch(() => {
      ToastHelper.error('Oh No! Something went wrong');
    });
  };

  const handleTypesFormChange = (types) => {
    if (JSON.stringify(types) !== JSON.stringify(categoryData.types)) {
      setCategoryData((prevData) => ({
        ...prevData,
        types: types,
      }));
    }
  }

  const handleOccupationFormChange = (occupations) => {
    if (JSON.stringify(occupations) !== JSON.stringify(
        categoryData.occupations)) {
      setCategoryData((prevData) => ({
        ...prevData,
        occupations: occupations,
      }));
    }
  };

  const handleAuthorFormChange = (authors) => {
    if (JSON.stringify(authors) !== JSON.stringify(categoryData.authors)) {
      setCategoryData((prevData) => ({
        ...prevData,
        authors: authors,
      }));
    }
  };

  const handleLawFormChange = (laws) => {
    if (JSON.stringify(laws) !== JSON.stringify(categoryData.laws)) {
      setCategoryData((prevData) => ({
        ...prevData,
        laws: laws,
      }));
    }
  };

  const handleKeywordFormChange = (keywords) => {
    if (JSON.stringify(keywords) !== JSON.stringify(categoryData.keywords)) {
      setCategoryData((prevData) => ({
        ...prevData,
        keywords: keywords,
      }));
    }
  };

  const handleButtonSave = () => {
    const types = categoryData.types;
    const occupationIds = categoryData.occupations.map(
        occupation => occupation.occupationId);
    const authorIds = categoryData.authors.map(author => author.authorId);
    const lawIds = categoryData.laws.map(law => law.lawId);
    const keywords = categoryData.keywords;

    setTourStepIndex((prev) => prev + 1);

    switch (activeForm) {
      case labels[0]:
        handleTypesSave(types);
        break;
      case labels[1]:
        handleOccupationsSave(occupationIds);
        break;
      case labels[2]:
        handleAuthorsSave(authorIds);
        break;
      case labels[3]:
        handleLawsSave(lawIds);
        break;
      case labels[4]:
        handleKeywordsSave(keywords);
        break;
      default:
        break;
    }
  };

  const handleStepIndexChange = (newIndex) => {
    setTourStepIndex(newIndex);
  };

  const handleActiveForm = (newForm) => {
    setActiveForm(newForm);
    setTourStepIndex((prev) => prev + 1);
  }

  return (
      <div id="category" className="category">
        <TourModal steps={steps} page='Preferences' stepIndex={tourStepIndex} onStepIndexChange={handleStepIndexChange}  run={startTour} setRun={setStartTour}/>
        <h1>Preferences</h1>
        <div className="nav-buttons-container-mobile">
          <NavButtons
              labels={labels}
              activeForm={activeForm}
              onFormToggle={handleActiveForm}
          />
        </div>
        <div className="category-container">
          <div className="nav-buttons-container">
            <NavButtons
                labels={labels}
                activeForm={activeForm}
                onFormToggle={handleActiveForm}
            />
          </div>
          <div className="edit-category-content">
            <div className="forms-container">
              <div id="form_container" className="category-form-container">
                <CategoryForm
                    categories={categoryData}
                    occupations={occupationData}
                    authors={authorData}
                    laws={lawData}
                    keywords={keywordData}
                    onSaveTypes={handleTypesFormChange}
                    onSaveOccupation={handleOccupationFormChange}
                    onSaveAuthor={handleAuthorFormChange}
                    onSaveLaw={handleLawFormChange}
                    onSaveKeyword={handleKeywordFormChange}
                    showTypesForm={activeForm === labels[0]}
                    showOccupationForm={activeForm === labels[1]}
                    showAuthorForm={activeForm === labels[2]}
                    showLawForm={activeForm === labels[3]}
                    showKeywordForm={activeForm === labels[4]}
                    onSaveButtonClick={handleButtonSave}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={2000}/>
      </div>
  );
};