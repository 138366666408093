import React, {useEffect, useState} from 'react';
import '../styles/Pricing.css';
import {useNavigate} from "react-router-dom";
import {PublicService} from "../services/PublicService";
import PricingContainer from "../components/PricingContainer";

export const Pricing = () => {
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [isYearly, setIsYearly] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState("");

  useEffect(() => {
    PublicService.getAllSubscriptionsByPeriod(isYearly ? 'year' : 'month')
    .then((res) => {
      setSubscriptionData(res.data);
    })
    .catch(() => {
      navigate("/")
    });
    PublicService.getPublicSettings()
    .then((res) => {
      const data = res.data
      setTrialPeriod(data.freeTrialDays)
    })
    .catch(() => {
      navigate("/");
    })
  }, [isYearly, navigate]);

  const handleSelectPlan = (id) => {
    navigate('/register?planId=' + id)
  };

  const handleToggle = (value) => {
    setIsYearly(value);
  };

  return (
      <div className="pricing">
        <PricingContainer
            subscriptionData={subscriptionData}
            isYearly={isYearly}
            trialPeriod={trialPeriod}
            handleToggle={handleToggle}
            handleSelectPlan={handleSelectPlan}
        />
      </div>
  );
};
