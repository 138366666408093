import React, {useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Profile.css';
import {useProfile} from '../api/useProfile';
import NavButtons from "../components/NavButtons";
import {LawyerService} from "../services/LawyerService";
import ProfileForm from "../components/ProfileForm";
import {NotificationService} from "../services/NotificationService";
import ToastHelper from "../helpers/ToastHelper";
import TourModal from "../components/TourModal";
import {getProfileTourSteps} from "../steps/TourSteps";

export const Profile = () => {
  const {lawyer, notification} = useProfile();
  const [activeForm, setActiveForm] = useState("Edit Profile");
  const [lawyerData, setLawyerData] = useState({});
  const [notificationData, setNotificationData] = useState({});
  const [tourStepIndex, setTourStepIndex] = useState(0);
  const [startTour, setStartTour] = useState(sessionStorage.getItem('enableProfileTour'));
  const [steps, setSteps] = useState([]);

  const handleEditProfileFormSave = () => {

      LawyerService.updateLawyerProfile(null, lawyerData?.firstName,
          lawyerData?.lastName)
      .then(() => {
        ToastHelper.success("Successfully updated");
      })
      .catch(() => {
        ToastHelper.error("Oh No! Something went wrong");
      });
  };

  const handleNotificationFormSave = () => {
    NotificationService.updateNotifications(notificationData?.email, null,
        notificationData?.isEmailEnabled,
        false)
    .then(() => {
      ToastHelper.success("Successfully updated");
    })
    .catch(() => {
      ToastHelper.error("Oh No! Something went wrong");
    });
  };

  const handleEditProfileFormChange = (data) => {
    setLawyerData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleNotificationFormChange = (data) => {
    setNotificationData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleStepIndexChange = (newIndex) => {
    setTourStepIndex(newIndex);
  };

  const handleButtonSave = () => {

    if (activeForm === "Edit Profile") {
      handleEditProfileFormSave()
    } else {
      handleNotificationFormSave();
    }

    setTourStepIndex((prev) => prev + 1);
  }

  const handleActiveForm = (newForm) => {
    setActiveForm(newForm);
    setTourStepIndex((prev) => prev + 1);
  }

  const navButtonLabels = ["Edit Profile", "Notifications"];

  useEffect(() => {
    setSteps(getProfileTourSteps);
  }, []);

  return (
      <div id="profile" className="profile">
        <TourModal steps={steps} page='Profile' stepIndex={tourStepIndex} onStepIndexChange={handleStepIndexChange} run={startTour} setRun={setStartTour}/>
        <h1>Profile</h1>
        <div className="nav-buttons-container-mobile">
          <NavButtons
              labels={navButtonLabels}
              activeForm={activeForm}
              onFormToggle={handleActiveForm}
          />
        </div>
        <div className="profile-container">
          <div className="nav-buttons-container">
            <NavButtons
                labels={navButtonLabels}
                activeForm={activeForm}
                onFormToggle={handleActiveForm}
            />
          </div>
          <div className="edit-profile-content">
            <div className="forms-container">
              <div id="form_container" className="profile-form-container">
                <ProfileForm
                    editProfileData={lawyer}
                    notificationData={notification}
                    onChangeEditProfile={handleEditProfileFormChange}
                    onSaveNotification={handleNotificationFormChange}
                    showEditProfileForm={activeForm === 'Edit Profile'}
                    showNotificationForm={activeForm === 'Notifications'}
                    onSaveButtonClick={handleButtonSave}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={2000}/>
      </div>
  );
};
