import {axiosInstance, setAuthToken} from "../api/axios";

export const PaymentService = {

  createCheckoutSession(paymentId, isRecurring, returnUrl) {
    setAuthToken();
    return axiosInstance.post('/api/payment/create-checkout-session',
        {paymentId, isRecurring, returnUrl});
  },

  getSessionStatus(sessionId, paymentId) {
    setAuthToken();
    return axiosInstance.post(
        '/api/payment/session-status',
        {sessionId: sessionId, paymentId: paymentId});
  }
};
