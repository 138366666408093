import React from 'react';
import { FaPowerOff } from 'react-icons/fa';
import '../styles/TopBar.css'

export const TopBar = ({ handleSignOut }) => {
  return (
      <div className="top-bar">
        <button className="sign-out-button" onClick={handleSignOut}>
          <FaPowerOff />
        </button>
      </div>
  );
};
