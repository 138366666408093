export const clearStorageValues = () => {
  localStorage.removeItem("lawyer");
  sessionStorage.removeItem("sidebarSelectedOption");
};

export const enableTourGuides = () => {
  sessionStorage.setItem('enableDashboardTour', true);
  sessionStorage.setItem('enableProfileTour', true);
  sessionStorage.setItem('enablePreferencesTour', true);
}

export const getLawyer = JSON.parse(localStorage.getItem('lawyer')) || {};