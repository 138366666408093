import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const TourModal = ({ steps, page, stepIndex, onStepIndexChange, run, setRun }) => {
  const handleCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      onStepIndexChange && onStepIndexChange(newIndex);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {

      switch (page) {
        case 'Profile':
          sessionStorage.removeItem('enableProfileTour');
          break;
        case 'Preferences':
          sessionStorage.removeItem('enablePreferencesTour');
          break;
        case 'Dashboard':
        default:
          sessionStorage.removeItem('enableDashboardTour');
          break;
      }

      setRun(false);
    }
  };

  return (
      <Joyride
          callback={handleCallback}
          run={run}
          stepIndex={stepIndex}
          steps={steps}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={{
            options: {
              arrowColor: '#f5f5f5',
              backgroundColor: '#f5f5f5',
              primaryColor: '#283347',
              textColor: '#333333',
              width: 300,
              zIndex: 1000,
            },
          }}
      />
  );
};

export default TourModal;
