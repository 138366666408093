import React from 'react';
import '../styles/GenericForm.css';
import PreferencesCategoryForm from "./PreferencesCategoryForm";

export const LawForm = ({
  lawData,
  selectedLaws,
  onSaveLaw
}) => {

  return (
      <PreferencesCategoryForm
          categoryData={lawData.laws}
          selectedCategories={selectedLaws}
          onSaveCategory={onSaveLaw}
          title="By Law"
          label="law"
          description="These are types of laws"
          idField="lawId"
          nameField="name"
          placeholderText="Please search and select"
      />
  );
};

