import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Profile.css';
import NavButtons from "../components/NavButtons";
import SettingsForm from "../components/SettingsForm";
import { UserService } from "../services/UserService";
import { useNavigate } from "react-router-dom";
import { clearStorageValues } from "../helpers/StorageHelper";
import ConfirmationAlert from "../components/ConfirmationAlert";

export const Settings = () => {
  const navigate = useNavigate();
  const [activeForm, setActiveForm] = useState("Account Settings");
  const [openDialog, setOpenDialog] = useState(false);

  const handleActiveForm = (newForm) => {
    setActiveForm(newForm);
  }

  const handleOnDeleteAccount = () => {
    UserService.deleteUser()
    .then(() => {
      clearStorageValues()
      navigate('/')
    })
    .catch((error) => {
      console.error('Error delete user:', error);
    });
  }

  const handleOnClickDelete = () => {
    setOpenDialog(true);
  }

  const handleConfirmDelete = () => {
    handleOnDeleteAccount();
    setOpenDialog(false);
  }

  const navButtonLabels = ["Account Settings"];

  return (
      <div id="profile" className="profile">
        <h1>Settings</h1>
        <div className="nav-buttons-container-mobile">
          <NavButtons
              labels={navButtonLabels}
              activeForm={activeForm}
              onFormToggle={handleActiveForm}
          />
        </div>
        <div className="profile-container">
          <div className="nav-buttons-container">
            <NavButtons
                labels={navButtonLabels}
                activeForm={activeForm}
                onFormToggle={handleActiveForm}
            />
          </div>
          <div className="edit-profile-content">
            <div className="forms-container">
              <div id="form_container" className="profile-form-container">
                <SettingsForm
                    showAccountForm={activeForm === 'Account Settings'}
                    onDeleteAccount={handleOnClickDelete}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmationAlert
            title="Delete account"
            message="Permanently delete your account? You can't undo this."
            confirmLabel="Delete"
            cancelLabel="Cancel"
            onConfirm={handleConfirmDelete}
            handleClose={() => setOpenDialog(false)}
            onLoad={openDialog}
        />
        <ToastContainer position="top-right" style={{ color: '#fff' }} autoClose={2000} />
      </div>
  );
};
