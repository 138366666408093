import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../styles/ConfirmationAlert.css';

const ConfirmationAlert = ({
  title = 'Confirm to submit',
  message = 'Are you sure you want to do this?',
  confirmLabel = 'Yes',
  cancelLabel = 'No',
  onConfirm,
  handleClose,
  onLoad,
}) => {
  useEffect(() => {
    if (onLoad) {
      handleConfirm();
    }
  }, [onLoad]);

  const handleConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const handleConfirmClick = () => {
          onConfirm();
          onClose();
        };

        const handleCancelClick = () => {
          onClose();
          handleClose();
        };

        return (
            <div className="custom-alert">
              <h1>{title}</h1>
              <p>{message}</p>
              <div className="custom-alert-buttons">
                <button className="cancel-button" onClick={handleCancelClick}>
                  {cancelLabel}
                </button>
                <button className="confirm-button" onClick={handleConfirmClick}>
                  {confirmLabel}
                </button>
              </div>
            </div>
        );
      },
      closeOnClickOutside: false,
    });
  };

  return null;
};

export default ConfirmationAlert;
