import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer = () => {
  return (
      <footer className="footer">
        <div className="footer-container">
          {/*<div className="footer-section">*/}
          {/*  <h2>Quick Links</h2>*/}
          {/*  <ul>*/}
          {/*    <li><a href="/">Home</a></li>*/}
          {/*    <li><a href="pricing">Pricing</a></li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          <div className="footer-section">
            <h2>Follow Us</h2>
            <div className="social-icons">
              <a href="https://www.facebook.com/people/Info-Lex/61560866829374" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              {/*<a href="https://twitter.com" target="_blank" rel="noopener noreferrer">*/}
              {/*  <FaTwitter />*/}
              {/*</a>*/}
              <a href="https://www.instagram.com/infolex.eu" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/infolexeu" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Info-Lex. All rights reserved.</p>
        </div>
      </footer>
  );
};

export default Footer;