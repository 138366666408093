import { Link } from "react-router-dom";
import { NavItem } from "./NavItem";
import "../styles/header.css"
import logo from "../images/infolex_logo_with_text_4.png";
import {RedirectButton} from "./RedirectButton";
import React from "react";

export const Header = () => {
  return (
      <header>
        <nav className="navbar navbar-expand-md navbar-dark main-header">
          <div className="container main-header-container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/" className="navbar-brand" style={{ display: "flex", alignItems: "center" }}>
                <img
                    src={logo}
                    alt={`logo`}
                    height={40}
                    width={112}
                />
              </Link>
            </div>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse ml-4 navbar-collapse" id="navbarNav">
              <ul className="navbar-nav align-items-center">
                <NavItem to="/">Home</NavItem>
                <NavItem to="/pricing">Pricing</NavItem>
              </ul>
              <ul className="navbar-nav ml-auto align-items-center">
                <NavItem to="/login">Sign In</NavItem>
                <RedirectButton to="/pricing">Register</RedirectButton>
              </ul>
            </div>
          </div>
        </nav>
      </header>
  );
};
