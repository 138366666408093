import React, {useEffect, useState} from 'react';
import '../styles/GenericForm.css';
import Select from 'react-select';
import SelectedOption from './SelectedOption';
import {getLawyer} from "../helpers/StorageHelper";

const PreferencesCategoryForm = ({
  categoryData,
  selectedCategories,
  onSaveCategory,
  title,
  label,
  description,
  idField,
  nameField,
  placeholderText,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const limit = getLawyer?.subscriptionLimit;

  useEffect(() => {
    if (selectedOptions.length === 0 && selectedCategories) {
      setSelectedOptions(
          selectedCategories.map(
              ({[idField]: categoryId, [nameField]: name}) => ({
                [idField]: categoryId,
                [nameField]: name,
              }))
      );
    }
  }, [selectedCategories, idField, nameField]);

  useEffect(() => {
    if (Array.isArray(categoryData)) {
      const remainingOptions = categoryData.filter(
          (category) =>
              !selectedOptions.some(
                  (selectedOption) => selectedOption[idField]
                      === category[idField]
              )
      );

      const newOptions = remainingOptions.map((category) => ({
        value: category[idField],
        label: category[nameField],
      }));

      setOptions(newOptions);
    }
  }, [categoryData, idField, nameField, selectedOptions]);

  useEffect(() => {
    onSaveCategory(selectedOptions);
  }, [selectedOptions, onSaveCategory]);

  const handleOptionSelect = (option) => {
    if (
        !selectedOptions.some(
            (selectedOption) => selectedOption[idField] === option.value
        ) && selectedOptions.length < limit
    ) {
      setSelectedOptions([
        ...selectedOptions,
        {[idField]: option.value, [nameField]: option.label},
      ]);
    }
  };

  const handleOptionRemove = (option) => {
    const updatedOptions = selectedOptions.filter(
        (selected) => selected[idField] !== option[idField]
    );
    setSelectedOptions(updatedOptions);
  };

  return (
      <div className="form-container-categories">
        <div className="section-title">
          <div className="form-row">
            <div className="form-column">
              <h2>{title}</h2>
            </div>
            <h2>{selectedOptions.length}/{limit}</h2>
          </div>
          <div className="form-row">
            <div className="form-column">
              <h4>{description}</h4>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <label className="form-label">Select types of {label}:</label>
            <Select
                options={options}
                isSearchable={true}
                onChange={handleOptionSelect}
                placeholder={placeholderText}
                controlShouldRenderValue={false}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-column">
            <div className="selected-options">
              {selectedOptions &&
              selectedOptions.map((option) => (
                  <SelectedOption
                      key={option[idField]}
                      option={option}
                      onRemove={handleOptionRemove}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default PreferencesCategoryForm;