import {axiosInstance} from "../api/axios";

export const PublicService = {
  getAllSubscriptionsByPeriod(period) {
    return axiosInstance.get(`/api/main/all/${period}`);
  },

  getPublicSettings() {
    return axiosInstance.get('/api/main/settings/all')
  }
};