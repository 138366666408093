import { NavLink } from "react-router-dom";
import "../styles/NavItem.css"

export const NavItem = ({ to, children }) => {
  return (
      <li className="nav-item">
        <NavLink
            to={to}
            className="nav-link"
        >
          <div className="nav-item-content">
            <span className="nav-item-text">{children}</span>
            <span className="nav-item-underline"/>
          </div>
        </NavLink>
      </li>
  );
};
