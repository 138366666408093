import {useEffect, useState} from "react";
import {setAuthToken} from "./axios";
import {LawyerService} from "../services/LawyerService";
import {useNavigate} from "react-router-dom";

export function useLawyer() {
  const navigate = useNavigate();
  const [lawyer, setLawyer] = useState({});

  useEffect(() => {
    // Fetch the data from the API when the component mounts
    setAuthToken();

    LawyerService.getLawyer()
    .then(response => {
      setLawyer(response.data)
    })
    .catch(() => {
      navigate('/login');
    })
  }, [navigate]);

  return { lawyer };
}