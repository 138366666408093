import React from 'react';
import '../styles/AdminForm.css';
import {LawyerList} from "./LawyerList";
import {UserForm} from "./UserForm";

const AdminForm = ({
  lawyerData,
  selectedLawyerData,
  subscriptionData,
  showLawyerList,
  showNewUser,
  showSelectedUser,
  onClickNewUser,
  onSelectUser,
  onChangeUser,
  onSaveUser,
  onDeleteUser,
}) => {
  return (
      <div>
        <div className="admin-form">
          {showLawyerList ? (
              <LawyerList lawyerData={lawyerData} onClickNewUser={onClickNewUser} onSelectUser={onSelectUser}/>
          ) : null}
          {showNewUser ? (
              <UserForm onChangeUser={onChangeUser} subscriptions={subscriptionData} onSaveUser={onSaveUser} isNew={true}/>
          ) : null}
          {showSelectedUser ? (
              <UserForm userData={selectedLawyerData} subscriptions={subscriptionData} onChangeUser={onChangeUser} onSaveUser={onSaveUser} onDeleteUser={onDeleteUser} isAdmin={true}/>
          ) : null}
        </div>
      </div>
  );
};

export default AdminForm;
