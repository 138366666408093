export const getDashboardTourSteps = [
  {
    target: 'body',
    content: 'Welcome to Infolex – your portal to the latest European legislations. Let\'s get you started!',
    placement: 'center',
  },
  {
    target: '#dashboard',
    content: 'This is the Dashboard. Check this for the latest legislations released today and stay informed effortlessly!',
    placement: 'right',
  },
  {
    target: '#column_boxes',
    content: 'These are today\'s published regulations, decisions, and directives listed by number',
    placement: 'right',
  },
  {
    target: '#document_table_container',
    content: 'Discover more information about today\'s legal documents.',
    placement: 'right',
  },
  {
    target: '#document_table_container',
    content: 'You can also retrieve the official legal documents by clicking on the PDF or HTML buttons.',
    placement: 'right',
  },
  {
    target: '#profile_nav',
    content: 'Please click on \'Profile\' to redirect to the Profile page.',
    placement: 'right',
    hideFooter: true,
  },
]

export const getProfileTourSteps = [
  {
    target: '#profile',
    content: 'Welcome to the Profile Page! Explore your user details and notification settings.',
    placement: 'center',
  },
  {
    target: '#form_container',
    content: 'The \'Edit Profile\' section includes your personal details',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#user_form_form_row_1',
    content: 'Please fill your first name and last name, then proceed to the next step.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#profile_form_save_btn',
    content: 'Please click on the \'Save\' button to save your details',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#root > div > div.content > div > div.profile-container > div.nav-buttons-container > div > div:nth-child(2)',
    content: 'Please click on the \'Notification\' button',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'The \'Notifications\' section outlines your preferences for how you wish to receive notifications from us.',
    placement: 'right',
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Your current notification email is set to your personal email, but feel free to update it to a different one if you wish.',
    placement: 'right',
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Additionally, you can manage your notifications by toggling the blue switch to enable or disable them.',
    placement: 'right',
    disableScrolling: true,
  },
  {
    target: '#preferences_nav',
    content: 'Please click on \'Preferences\' to redirect to the Preferences page.',
    placement: 'right',
    hideFooter: true,
    disableScrolling: true,
  },
]

export const getPreferencesTourSteps = [
  {
    target: '#category',
    content: 'This is the Preferences page! Set your preferences based on any specific criteria regarding your customers',
    placement: 'center',
    disableBeacon: true,
  },
  {
    target: '#form_container',
    content: 'The \'By Type\' section includes options to specify the types of legislation you wish to receive.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Please select the types you desire, then proceed to the next step.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#profile_form_save_btn',
    content: 'Please click on the \'Save\' button to save your details',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#root > div > div.content > div > div.category-container > div.nav-buttons-container > div > div:nth-child(2)',
    content: 'Please click on the \'By Occupation\' button',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'The \'By Occupation\' section includes options to specify your preferences for different occupations',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Please select the occupations you desire, then proceed to the next step.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#profile_form_save_btn',
    content: 'Please click on the \'Save\' button to save your details',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#root > div > div.content > div > div.category-container > div.nav-buttons-container > div > div:nth-child(3)',
    content: 'Please click on the \'By Author\' button',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'The \'By Author\' section includes options to specify your preferences for different authors',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Please select the authors you desire, then proceed to the next step.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#profile_form_save_btn',
    content: 'Please click on the \'Save\' button to save your details',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#root > div > div.content > div > div.category-container > div.nav-buttons-container > div > div:nth-child(4) > button',
    content: 'Please click on the \'By Law\' button',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'The \'By Law\' section includes options to specify your preferences for different laws',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Please select the law you desire, then proceed to the next step.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#profile_form_save_btn',
    content: 'Please click on the \'Save\' button to save your details',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#root > div > div.content > div > div.category-container > div.nav-buttons-container > div > div:nth-child(5) > button',
    content: 'Please click on the \'By Keyword\' button',
    placement: 'right',
    hideFooter: true,
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'The \'By Keyword\' section includes options to specify your preferences for any custom keyword',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  },
  {
    target: '#form_container',
    content: 'Please type the keyword you desire, then proceed to the next step.',
    placement: 'right',
    spotlightClicks: true,
    disableScrolling: true,
  }
]